import React from 'react';
import chroma from 'chroma-js';
import { IconButton, Paper, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TEAMCOLORS as colors } from '../../models/Models';
import { ConstraintListProps } from './types';

export function ConstraintCards(props: ConstraintListProps) {
	const { t } = useTranslation();

	return (
		<div
			style={{
				margin: '0px 0px 15px 10px',
				display: 'grid',
				gridTemplateColumns: '1fr 1fr 1fr 1fr',
				gap: '5px',
			}}
		>
			{props.constraints.map((constraint, constraintIndex) => {
				let constraintString;
				if (constraint.min === constraint.max) {
					if (constraint.min === 0)
						constraintString = `${t(constraint.name)} ${t('None')}`;
					else constraintString = `${t(constraint.name)} ${constraint.min}`;
				} else if (!constraint.min && constraint.max === 0)
					constraintString = `${t(constraint.name)} ${t('None')}`;
				else
					constraintString = `${t(constraint.name)} ${
						constraint.min ? constraint.min : '0'
					} - ${constraint.max ? constraint.max : ''}`;

				const isActive =
					props.activeConstraint.active &&
					props.activeConstraint.team === props.bagID &&
					props.activeConstraint.constraintIndex === constraintIndex;
				return (
					<Paper
						key={constraintIndex}
						elevation={2}
						style={{
							width: '100%',
							border: isActive
								? '1px solid black'
								: `1px solid ${colors[props.bagID - 1].hex()}`,
							padding: '0px 3px 5px 10px',
							backgroundColor: colors[props.bagID - 1].hex(),
							color:
								chroma.contrast(colors[props.bagID - 1].hex(), '#000') < 5.5
									? '#fff'
									: '#000',
							display: 'inline-block',
							height: '32px',
							boxSizing: 'border-box',
							cursor: isActive ? 'grab' : 'pointer',
						}}
						onClick={() => {
							props.setUserSelections({
								teamBagId: props.bagID,
								constraint: constraint.name,
								min: constraint.min,
								max: constraint.max,
								disableMin: false,
								disableMax: false,
							});
							props.setActiveConstraint({
								active: true,
								team: props.bagID,
								constraintIndex: constraintIndex,
							});
						}}
					>
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: '1fr min-content',
							}}
						>
							<Typography
								style={{
									marginTop: '6px',
								}}
								variant="caption"
							>
								{constraintString}
							</Typography>
							<IconButton
								size="small"
								onClick={(event) => {
									event.stopPropagation(); // don't trigger parent onclick
									props.removeConstraint(props.bagID, constraintIndex);
								}}
							>
								<CloseIcon
									fontSize="small"
									style={{
										color:
											chroma.contrast(colors[props.bagID - 1].hex(), '#000') <
											5.5
												? '#fff'
												: '#000',
									}}
								/>
							</IconButton>
						</div>
					</Paper>
				);
			})}
			{Array(4 - props.constraints.length)
				.fill('')
				.map((_, index) => {
					return (
						<Paper
							key={props.constraints.length + index}
							elevation={0}
							style={{
								width: '100%',
								border: '1px dashed lightgrey',
								padding: '3px 3px 5px 10px',
								backgroundColor: 'white',
								color: '#000',
								display: 'inline-block',
								height: '32px',
								boxSizing: 'border-box',
							}}
						/>
					);
				})}
		</div>
	);
}
