import { Operation } from '../../models/Models';

export function dateToHM(date: Date): number {
	return date.getHours() * 60 + date.getMinutes();
}

export function isColliding(opA: Operation, opB: Operation) {
	return (
		opA.start + opA.preOpTime < opB.start + opB.preOpTime + opB.opTime &&
		opB.start + opB.preOpTime < opA.start + opA.preOpTime + opA.opTime
	);
}

export function isOverlapping(opA: Operation, opB: Operation) {
	return (
		opA.start < opB.start + opB.duration && opB.start < opA.start + opA.duration
	);
}
