import React, { useState, useEffect } from 'react';
import { Operation } from '../../models/Models';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Typography,
} from '@mui/material';
import { TField } from '../Common/Common';
import { useTranslation, Trans } from 'react-i18next';
import { OperationDialogProps } from './types';

export const OperationDialog = (props: OperationDialogProps) => {
	const { t } = useTranslation();
	const { operation } = props;

	const [state, setState] = useState({
		canSave: false,
		name: props.operation.name,
		preOpTime: props.operation.preOpTime,
		opTime: props.operation.opTime,
		postOpTime: props.operation.postOpTime,
	});

	const handleChangeName = (event: any) => {
		const newValue: string = event.target.value as string;
		setState({ ...state, name: newValue, canSave: true });
	};

	const handleChangeTime = (event: any, stateColName: string) => {
		const newValue: number = Number(event.target.value);
		if (newValue > 0)
			setState({ ...state, [stateColName]: newValue, canSave: true });
	};

	/* Updatting selection based on incoming props.operation data */
	useEffect(() => {
		setState({
			...state,
			name: props.operation.name,
			preOpTime: props.operation.preOpTime,
			opTime: props.operation.opTime,
			postOpTime: props.operation.postOpTime,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.operation]);

	const save = () => {
		/* calling callback function updating operation */
		props.editOperation(
			new Operation({
				// new Operation object
				operationId: props.operation.operationId,
				name: state.name,
				preOpTime: state.preOpTime,
				opTime: state.opTime,
				postOpTime: state.postOpTime,
				room: props.operation.room,
				start: props.operation.start,
			})
		);
	};

	return (
		<Dialog
			open={props.open}
			onClose={(event, reason) => {
				if (reason === 'escapeKeyDown') props.closeWindow();
			}}
		>
			<DialogTitle>{t('Change Operation')}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<Trans i18nKey="welcomeUser">
						<strong>Note:</strong>
						<em>not</em>
					</Trans>
				</DialogContentText>
				<TField
					label={t('Operation ID')}
					defaultValue={operation.operationId}
					disabled
					fullWidth
				/>
				<TField
					label={t('Patient name')}
					defaultValue={state.name}
					fullWidth={true}
					onChange={(e) => handleChangeName(e)}
				/>
				<TField
					label={t('Pre-op duration')}
					defaultValue={operation.preOpTime}
					helperText={t('in minutes')}
					type="number"
					fullWidth={true}
					onChange={(e) => handleChangeTime(e, 'preOpTime')}
				/>
				<TField
					label={t('Operation duration')}
					defaultValue={operation.opTime}
					helperText={t('in minutes')}
					type="number"
					fullWidth={true}
					onChange={(e) => handleChangeTime(e, 'opTime')}
				/>
				<TField
					label={t('Post-op duration')}
					defaultValue={operation.postOpTime}
					helperText={t('in minutes')}
					type="number"
					fullWidth={true}
					onClick={(e) => handleChangeTime(e, 'postOpTime')}
				/>

				<Typography variant="body1">
					{t('OperationDialog Total Time', {
						minutes: `${state.preOpTime + state.opTime + state.postOpTime}`,
					})}
				</Typography>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={() => props.closeWindow()}
					color="primary"
					variant="contained"
				>
					{t('Cancel')}
				</Button>

				<Button
					disabled={!state.canSave}
					variant="contained"
					color="primary"
					onClick={() => save()}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};
