import { Paper, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const useStyles = makeStyles((_) => ({
	buttonDiv: {
		marginTop: '1rem',
		gap: '1rem ',
		display: 'flex',
	},

	container: {
		margin: 'auto',
		width: 'fit-content',
		padding: '1rem',
		border: '1px solid #ccc',
	},
}));

function PageNotFound() {
	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Paper elevation={0} className={classes.container}>
			<Typography variant="h2">Ooops!</Typography>
			<Typography variant="h6">404</Typography>
			<Typography variant="body2" component="p">
				Den begärda sidan finns inte.
			</Typography>

			<div className={classes.buttonDiv}>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => navigate(-1)}
				>
					Tillbaks
				</Button>

				<Link to="/" style={{ textDecoration: 'none' }}>
					<Button variant="contained" color="primary">
						Start
					</Button>
				</Link>
			</div>
		</Paper>
	);
}

export default PageNotFound;
