import React from 'react';
import { Paper, Tooltip, Typography, IconButton } from '@mui/material';
import { DragHandle, LockOpen, Lock } from '@mui/icons-material';
import { PROFESSION_STR, SPECIALISATION_STR } from '../../models/Models';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { StaffCardProps } from './types';

const StaffCard = (props: StaffCardProps) => {
	const { t } = useTranslation();

	/* Color background and text & icons (dragHandle & lock) */
	const backgroundColor = chroma(props.color);
	const contentColor =
		chroma.contrast(backgroundColor.hex(), '#000') < 5.5 ? '#fff' : '#000';
	const profession_str = PROFESSION_STR[props.staff.profession];
	const specialisation_str = SPECIALISATION_STR[props.staff.specialisation];

	/**
	 * Handles user click on Card. Opens a window (CreateTeams, StaffDialog).
	 */
	const handleClick = () => {
		props.setOpenWindow(props.staff);
	};

	const handleLock = () => {
		const copy = [...props.staffColumns];
		copy[props.teamIndex].data[props.staffIndex].locked = !props.staff.locked;
		props.setStaffColumns(copy);
	};

	/* Lock component (none in first bag / locked / unlocked) */
	const ToggleLock = () =>
		props.teamIndex === 0 ? (
			<div />
		) : props.staff.locked ? (
			<Lock style={{ color: contentColor }} />
		) : (
			<LockOpen style={{ color: contentColor }} />
		);

	return (
		<Paper
			style={{
				backgroundColor: props.staff.locked
					? backgroundColor.darken().hex()
					: backgroundColor.hex(),
				color: contentColor,
				opacity: props.staff.available ? '1' : '0.3',
			}}
			className="draggable-card"
		>
			<div onClick={() => handleClick()}>
				<Typography variant="body2">{props.staff.name}</Typography>
				<Typography variant="caption">{t(profession_str)}</Typography>
				<Typography variant="body2">{t(specialisation_str)}</Typography>
			</div>
			<div>
				{!props.staff.available ? (
					<IconButton
						disableRipple
						disabled={!props.staff.available && props.teamIndex === 0}
						size="small"
						style={{ cursor: 'move' }}
						className="dragHandleOp"
					>
						<DragHandle style={{ color: contentColor }} />
					</IconButton>
				) : (
					<Tooltip
						title={String(t('DragInfoStaffCard'))}
						arrow
						placement="right"
					>
						<span>
							<IconButton
								disableRipple
								disabled={!props.staff.available && props.teamIndex === 0}
								size="small"
								style={{ cursor: 'move' }}
								className="dragHandleOp"
							>
								<DragHandle style={{ color: contentColor }} />
							</IconButton>
						</span>
					</Tooltip>
				)}
				<Tooltip title={String(t('LockTooltipIcon'))} arrow placement="right">
					<IconButton onClick={handleLock} size="small">
						<ToggleLock />
					</IconButton>
				</Tooltip>
			</div>
		</Paper>
	);
};

export default StaffCard;
