import React from 'react';
import {
	TextField,
	Select,
	styled,
	Snackbar as SnackbarMUI,
	Typography,
	SnackbarContent,
	Icon,
	IconButton,
	Tooltip,
	TypographyProps,
} from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import { Close } from '@mui/icons-material';
import ReactSelect from 'react-select';
import chroma from 'chroma-js';
import { TeamOptimizeSchedule } from '../OptimizeSchedule/types';
import { useTranslation } from 'react-i18next';
import { Container, ContainerOptions } from 'react-smooth-dnd';

/**
 * TextField for number and string input
 */
export const TField = styled(TextField)({
	display: 'block',
	marginBottom: '1rem',
});

/**
 * Select, dropdown menu
 */
export const TSelect = styled(Select)({
	display: 'block',
	marginBottom: '1rem',
});

/**
 * Converts number of minutes to hour and minutes in string format
 * @param duration number of minutes
 */
export const minutesToHM = (duration: number) => {
	return {
		hours: Math.floor(duration / 60),
		minutes: duration % 60,
	};
};

type TransitionProps = Omit<SlideProps, 'direction'>;

interface SnackbarProps {
	messageType: 'ok' | 'error' | 'warning'; // message type
	message: string; // message to user
	open: boolean; // true = show snackbar, false = hides snackbar
	handleClose: () => void;
	duration?: number | null; // how long the snackbar should stay open, defaults to null (no duration)
}

/**
 * Snackarbar, visible from below. Displays: Ok, Error or Warnings with text
 * @param props SnackbarProps
 */
export const Snackbar = (props: SnackbarProps) => {
	const { t } = useTranslation();

	let bgColor = '';
	let icon = '';
	switch (props.messageType) {
		case 'ok':
			bgColor = '#69827a';
			icon = 'check_circle';
			break;
		case 'error':
			bgColor = '#9F3F3F';
			icon = 'error';
			break;
		case 'warning':
			bgColor = '#cb8a36';
			icon = 'warning';
			break;
		default:
			break;
	}

	const SlideTransition = (props: TransitionProps) => (
		<Slide {...props} direction="up" />
	);

	const handleClose = (
		event: React.SyntheticEvent | MouseEvent,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}
		props.handleClose();
	};

	return (
		<SnackbarMUI
			style={{ zIndex: !props.open ? -999 : 999 }}
			open={props.open}
			autoHideDuration={props.duration}
			onClose={(event, reason) => reason === 'clickaway' && props.handleClose()}
			TransitionComponent={SlideTransition}
		>
			<SnackbarContent
				style={{ backgroundColor: bgColor, color: '#fff' }}
				message={
					<div style={{ display: 'grid', gridTemplateColumns: '30px auto' }}>
						<Icon>{icon}</Icon>

						<Typography variant="body1">{props.message}</Typography>
					</div>
				}
				action={
					<Tooltip title={String(t('Close window'))}>
						<IconButton style={{ color: '#fff' }} onClick={handleClose}>
							<Close />
						</IconButton>
					</Tooltip>
				}
			/>
		</SnackbarMUI>
	);
};

type TeamOpt = {
	label: string;
	value: number;
	active: boolean;
	color: string;
};

type TeamMultiSelectProps = {
	options: Array<TeamOpt>;
	setTeams: (teams: Array<TeamOptimizeSchedule>) => void;
};

export const TeamMultiSelect = (props: TeamMultiSelectProps) => {
	const { t } = useTranslation();

	const colourStyles = {
		control: (provided: any) => ({ ...provided, backgroundColor: 'white' }),
		option: (provided: any, state: any) => {
			const color = chroma(state.data.color);
			return {
				...provided,
				backgroundColor: state.isDisabled
					? null
					: state.isSelected
					? state.data.color
					: state.isFocused
					? color.alpha(0.1).css()
					: null,
				color: state.isDisabled
					? '#ccc'
					: state.isSelected
					? chroma.contrast(color, 'white') > 2
						? 'white'
						: 'black'
					: state.data.color,
				cursor: state.isDisabled ? 'not-allowed' : 'default',

				':active': {
					...provided[':active'],
					backgroundColor:
						!state.isDisabled &&
						(state.isSelected ? state.data.color : color.alpha(0.3).css()),
				},
			};
		},
		multiValue: (provided: any, state: { data: any }) => ({
			...provided,
			backgroundColor: state.data.color,
		}),
		multiValueLabel: (provided: any, state: { data: any }) => ({
			...provided,
			color: chroma.contrast(state.data.color, '#000') < 5.5 ? '#fff' : '#000',
		}),
		multiValueRemove: (provided: any, state: any) => ({
			...provided,
			color: state.data.color,
			':hover': {
				backgroundColor: state.data.color,
				color: 'white',
			},
		}),
	};

	return (
		<ReactSelect
			styles={colourStyles}
			isMulti
			closeMenuOnSelect={false}
			placeholder={t('select teams')}
			options={props.options}
			defaultValue={props.options.filter((item) => item.active)}
			onChange={(currentSel) => {
				if (currentSel) {
					const newSel = props.options
						.reduce<Array<TeamOpt>>((acc, current) => {
							acc.push({
								...current,
								active: (currentSel as Array<TeamOpt>).find(
									(item) => item.label === current.label
								)
									? true
									: false,
							});
							return acc;
						}, [])
						.map((item) => {
							return { name: item.label, active: item.active };
						});
					props.setTeams(newSel);
				}
			}}
		/>
	);
};

interface DnDContainerProps extends ContainerOptions {
	children: React.ReactNode;
}

/**
 * Wrapper for drag and drop container
 * @param props DnDContainerProps
 */
export const DnDContainer = (props: DnDContainerProps) => {
	return (
		<Container
			groupName="1"
			dragHandleSelector=".dragHandleOp"
			dragClass="opacity-ghost"
			animationDuration={500}
			dropPlaceholder={{
				animationDuration: 150,
				showOnTop: true,
				className: 'drop-preview',
			}}
			style={{ height: '100%' }} // required: since Container is non-visible
			{...props}
		>
			{props.children}
		</Container>
	);
};

/** Typography used for both light and dark-moode */
export const Typo = (props: TypographyProps) => {
	return (
		<Typography {...props} color="textPrimary">
			{props.children}
		</Typography>
	);
};
