import React, { useState } from 'react';
import {
	AppBar,
	Toolbar,
	Button,
	Tooltip,
	Link,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	IconButton,
	Typography,
	Menu,
	MenuItem,
} from '@mui/material';
import {
	Public as PublicIcon,
	Settings as SettingsIcon,
	Brightness4 as Brightness4Icon,
	Brightness7 as Brightness7Icon,
} from '@mui/icons-material';
import JSON from '../package.json';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import sownderLogo from './Sownder-logo-white-01.svg';
import { TEAMCOLORS as colors } from './models/Models';
import { APP_WIDTH } from './AppTheme';

/**
 * LanguageSelector component that displays all languages.
 * Enables user to change app language
 */
const LanguageSelector = () => {
	const { t } = useTranslation();
	const changeLanguage = (lng: any) => i18n.changeLanguage(lng);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleChoiceLanguage = (language: 'sv' | 'en') => {
		changeLanguage(language);
		setAnchorEl(null);
	};

	return (
		<div style={{ display: 'inline' }}>
			<Tooltip title={String(t('Choose language'))}>
				<IconButton
					style={{ color: 'white' }}
					onClick={(event: any) => setAnchorEl(event.currentTarget)}
				>
					<PublicIcon />
				</IconButton>
			</Tooltip>
			<Menu
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				anchorEl={anchorEl}
			>
				<MenuItem onClick={() => handleChoiceLanguage('sv')}>Svenska</MenuItem>
				<MenuItem onClick={() => handleChoiceLanguage('en')}>English</MenuItem>
			</Menu>
		</div>
	);
};

interface HeaderProps {
	isThemeLight: boolean;
	setIsThemeLight: (a: boolean) => void;
}

interface HeaderSettingsDialogProps extends HeaderProps {
	open: boolean;
	closeWindow: () => void;
}

/**
 * Settings menu
 * @param props HeaderProps
 */
const HeaderSettingsDialog = (props: HeaderSettingsDialogProps) => {
	const { t } = useTranslation();

	function setTheme(theme: String) {
		theme === 'light'
			? props.setIsThemeLight(true)
			: props.setIsThemeLight(false);
	}

	return (
		<Dialog open={props.open}>
			<DialogTitle>{String(t('Settings'))}</DialogTitle>
			<DialogContent>
				{String(t('Mode'))}
				<IconButton onClick={() => setTheme('light')}>
					<Brightness7Icon />
				</IconButton>
				<IconButton onClick={() => setTheme('dark')}>
					<Brightness4Icon />
				</IconButton>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.closeWindow} color="primary" variant="contained">
					{String(t('Close'))}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

/**
 * Header with AppBar at top center of page
 * @param props Headerprops
 */
export const Header = (props: HeaderProps) => {
	const [settingsOpen, setSettingsOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<HeaderSettingsDialog
				open={settingsOpen}
				closeWindow={() => setSettingsOpen(false)}
				isThemeLight={props.isThemeLight}
				setIsThemeLight={props.setIsThemeLight}
			/>
			<div style={{ marginBottom: '4rem' }}>
				<AppBar style={{ backgroundColor: '#555' }}>
					<Toolbar
						style={{
							minHeight: 'fit-content',
							padding: '0',
							width: APP_WIDTH,
							margin: '0 auto',
						}}
					>
						<Link href="/start" style={{ padding: '3px' }}>
							<img
								src={sownderLogo}
								style={{ width: '80px', verticalAlign: 'middle' }}
								alt="sownder-loggo-home"
							/>
						</Link>
						<div style={{ marginLeft: 'auto' }}>
							<LanguageSelector />
							<div style={{ display: 'inline' }}>
								<Tooltip title={String(t('Settings'))}>
									<IconButton
										style={{ color: 'white' }}
										onClick={() => setSettingsOpen(true)}
									>
										<SettingsIcon />
									</IconButton>
								</Tooltip>
							</div>
							<Typography
								variant="button"
								style={{
									backgroundColor: colors[3].hex(),
									color: 'white',
									padding: '2px 10px',
									borderRadius: '4px',
									marginLeft: '12px',
								}}
							>
								{JSON.version}
							</Typography>
						</div>
					</Toolbar>
				</AppBar>
			</div>
		</>
	);
};

export default Header;
