import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import Keycloak from 'keycloak-js';
import Start from './Start';
import Stepper from './Stepper/Stepper';
import Header from './Header';
import PageError from './PageNotFound';
import { darkTheme, lightTheme, APP_WIDTH } from './AppTheme';
import AxiosConfiguration from './AxiosConfiguration';

const App: React.FunctionComponent = () => {
	const [authenticated, setAuthenticated] = useState(false);
	const [keycloak, setKeycloak] = useState<
		Keycloak.KeycloakInstance | undefined
	>(undefined);
	const [isThemeLight, setIsThemeLight] = useState(true);

	useEffect(() => {
		const kc = Keycloak('/keycloak.json');
		kc.init({
			onLoad: 'login-required',
			checkLoginIframe: false,
		}).then((auth) => {
			setAuthenticated(auth);
			setKeycloak(kc);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (keycloak && authenticated) {
		document.body.style.background = isThemeLight ? 'white' : '#424242';
		return (
			<div style={{ margin: 'auto', width: APP_WIDTH }}>
				<Router>
					<AxiosConfiguration keycloak={keycloak} />
					<ThemeProvider theme={isThemeLight ? lightTheme : darkTheme}>
						<Header
							isThemeLight={isThemeLight}
							setIsThemeLight={setIsThemeLight}
						/>
						<Routes>
							<Route path="*" element={<PageError />} />
							<Route path="/" element={<Start />} />
							<Route path="/start" element={<Start />} />
							<Route path="/schedule" element={<Stepper />} />
						</Routes>
					</ThemeProvider>
				</Router>
			</div>
		);
	} else return <></>;
};

export default App;
