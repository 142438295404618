import React, { useState, useEffect, useContext } from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	DialogContentText,
	Select,
	SelectChangeEvent,
	MenuItem,
	TextField,
	Typography,
	FormControl,
	FormLabel,
	FormGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PresetConstraintsContext } from '../../providers/ConstraintsProvider';
import {
	ActiveConstraint,
	UserSelection,
	TeamSettingsDialogProps,
} from './types';
import { ConstraintCards } from './ConstraintList';

const STR_CONSTRAINTS = ['Nurses', 'Doctors', 'Seniors', 'Staff'];

export function TeamSettingsDialog(props: TeamSettingsDialogProps) {
	const { t } = useTranslation();

	const [disableOK, setDisableOK] = useState(true);

	/* Selected by users (shows in fields) */
	const [userSelections, setUserSelections] = useState<UserSelection>({
		teamBagId: -1,
		constraint: '',
		min: undefined,
		max: undefined,
		disableMin: true,
		disableMax: true,
	});

	/* Active when constraintCard has been clicked on (to modify) */
	const [activeConstraint, setActiveConstraint] = useState<ActiveConstraint>({
		active: false,
		team: -1,
		constraintIndex: -1,
	});

	const [presetSelection, setPresetSelection] = useState(0);

	useEffect(() => {
		props.addRefreshCallback(() => {
			setPresetSelection(0);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Disables "OK" until team, constraint and, min or max, is set
	 * Disables "OK" if min 0 (and no max)
	 * Disables "OK" if min > max
	 *
	 */
	useEffect(() => {
		setDisableOK(
			userSelections.teamBagId === -1 ||
				userSelections.constraint === '' ||
				(userSelections.min === undefined &&
					userSelections.max === undefined) ||
				(userSelections.min === 0 && userSelections.max === undefined)
		);
		if (userSelections.max && userSelections.min)
			setDisableOK(userSelections.min > userSelections.max);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSelections]);

	const clearSelections = () => {
		setUserSelections({
			teamBagId: -1,
			constraint: '',
			min: undefined,
			max: undefined,
			disableMin: true,
			disableMax: true,
		});
	};

	const handleValueChange = (minOrMax: string, newValue: number) => {
		minOrMax === 'min'
			? setUserSelections({ ...userSelections, min: newValue })
			: setUserSelections({ ...userSelections, max: newValue });
	};

	/** Sets constraint selected by user, on props.staff (when OK is clicked) */
	const setConstraint = () => {
		if (activeConstraint.active) {
			removeConstraint(activeConstraint.team, activeConstraint.constraintIndex);
			setActiveConstraint({ active: false, team: -1, constraintIndex: -1 });
		}

		const copy = [...props.staff];
		let constraintFound = false;

		props.staff.forEach((team, teamIndex) => {
			if (team.bagID === Number(userSelections.teamBagId)) {
				// Find selected team in props.staff
				team.constraints.forEach((constraint, constraintIndex) => {
					if (constraint.name === userSelections.constraint) {
						// Check if selected constraint already exists
						copy[teamIndex].constraints[constraintIndex] = {
							name: userSelections.constraint,
							min: userSelections.min,
							max: userSelections.max,
						}; // Change
						copy[teamIndex].constraints.push(
							copy[teamIndex].constraints[constraintIndex]
						); // Add to end
						copy[teamIndex].constraints.splice(constraintIndex, 1); // Remove
						constraintFound = true;
					}
				});
				if (!constraintFound) {
					copy[teamIndex].constraints.push({
						name: userSelections.constraint,
						min: userSelections.min,
						max: userSelections.max,
					});
				}
			}
		});
		props.setStaff(copy);

		clearSelections();
	};

	/** Removes constraint from state variable constraintCard */
	const removeConstraint = (teamIndex: number, constraintIndex: number) => {
		const copy = [...props.staff];
		copy[teamIndex].constraints.splice(constraintIndex, 1);
		props.setStaff(copy);
	};

	const { constraints } = useContext(PresetConstraintsContext);

	/** useEffect when non-zero preset is selected
	 * Note: presets will apply to ALL active teams
	 */
	useEffect(() => {
		if (presetSelection !== 0) {
			const newData = [...props.staff];
			props.staff.forEach((team, teamIndex) => {
				if (teamIndex !== 0 && team.active) {
					newData[teamIndex].constraints = [
						...constraints[presetSelection].teamConstraint,
					];
				} else {
					newData[teamIndex].constraints = [];
				}
			});
			props.setStaff(newData);
		} else {
			// reset all constraints
			const newData = [...props.staff];
			props.staff.forEach((team, teamIndex) => {
				newData[teamIndex].constraints = [];
			});
			props.setStaff(newData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [presetSelection]);

	const setSelectedContraint = (
		newTeamBagId?: number,
		newTeamName?: string
	) => {
		const teamBagId =
			newTeamBagId !== undefined ? newTeamBagId : userSelections.teamBagId;
		const teamName =
			newTeamName !== undefined ? newTeamName : userSelections.constraint;

		if (teamBagId !== -1) {
			const constraintIndex = props.staff[teamBagId].constraints.findIndex(
				(contraint) => contraint.name === teamName
			);
			if (constraintIndex !== -1) {
				setActiveConstraint({
					active: true,
					team: props.staff[teamBagId].bagID,
					constraintIndex: constraintIndex,
				});
				return;
			}
		}

		setActiveConstraint({
			active: false,
			team: -1,
			constraintIndex: -1,
		});
	};

	return (
		<Dialog open={props.open} fullWidth>
			<DialogContent>
				<DialogTitle style={{ fontWeight: 'bold' }}>
					{String(t('Conditions'))}
				</DialogTitle>
				<DialogContent>
					<FormControl style={{ width: '100%', marginBottom: '1.5rem' }}>
						<FormLabel>Presets</FormLabel>
						<Select
							fullWidth={true}
							value={String(presetSelection)}
							onChange={(event: SelectChangeEvent) => {
								setPresetSelection(Number(event.target.value));
								setUserSelections({
									...userSelections,
									teamBagId: -1,
									constraint: '',
									min: undefined,
									max: undefined,
									disableMax: true,
									disableMin: true,
								});
								setActiveConstraint({
									active: false,
									constraintIndex: -1,
									team: -1,
								});
							}}
						>
							{constraints.map((c, index) => (
								<MenuItem key={`preset-${index}`} value={index}>
									{c.presetName}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<DialogContentText variant="h6">
						{t('SetConditions')}
					</DialogContentText>
					<FormGroup style={{ marginBottom: '1rem' }} row>
						<FormControl style={{ width: '50%' }}>
							<div style={{ marginRight: '5px' }}>
								<FormLabel>{t('Team')}</FormLabel>
								<Select
									size="small"
									fullWidth
									value={
										userSelections.teamBagId !== -1
											? userSelections.teamBagId
											: ''
									}
									onChange={(event) => {
										setSelectedContraint(
											event.target.value as number,
											undefined
										);
										setUserSelections({
											...userSelections,
											teamBagId: event.target.value as number,
										});
										if (userSelections.constraint)
											setUserSelections({
												...userSelections,
												teamBagId: event.target.value as number,
												disableMin: false,
												disableMax: false,
											});
									}}
								>
									{props.staff
										.filter((team) => team.active === true)
										.slice(1)
										.map((team) => {
											return (
												<MenuItem key={team.bagID} value={team.bagID}>
													{team.name}
												</MenuItem>
											);
										})}
								</Select>
							</div>
						</FormControl>
						<FormControl style={{ width: '50%' }}>
							<div style={{ marginLeft: '5px' }}>
								<FormLabel>{t('Condition')}</FormLabel>
								<Select
									fullWidth
									value={userSelections.constraint}
									size="small"
									onChange={(event) => {
										setSelectedContraint(undefined, event.target.value);
										setUserSelections({
											...userSelections,
											constraint: event.target.value as string,
										});
										if (userSelections.teamBagId !== -1)
											setUserSelections({
												...userSelections,
												constraint: event.target.value as string,
												disableMin: false,
												disableMax: false,
											});
									}}
								>
									{STR_CONSTRAINTS.map((constraintName) => {
										return (
											<MenuItem key={constraintName} value={constraintName}>
												{String(t(constraintName))}
											</MenuItem>
										);
									})}
								</Select>
							</div>
						</FormControl>
					</FormGroup>
					<FormLabel>{t('Interval')}</FormLabel>
					<FormGroup style={{ marginTop: '0.5rem' }} row>
						<FormControl style={{ width: '40%', boxSizing: 'content-box' }}>
							<div style={{ marginRight: '5px' }}>
								<TextField
									fullWidth
									label="Min"
									size="small"
									disabled={userSelections.disableMin}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										inputProps: { min: 0, max: userSelections.max },
									}}
									type="number"
									onChange={(e) =>
										handleValueChange('min', Number(e.target.value))
									}
									value={
										userSelections.min !== undefined ? userSelections.min : ''
									}
								/>
							</div>
						</FormControl>

						<FormControl style={{ width: '40%' }}>
							<div style={{ marginLeft: '5px', marginRight: '10px' }}>
								<TextField
									fullWidth
									size="small"
									label="Max"
									disabled={userSelections.disableMax}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										inputProps: {
											min:
												userSelections.min !== undefined
													? userSelections.min
													: 0,
										},
									}}
									type="number"
									onChange={(e) =>
										handleValueChange('max', Number(e.target.value))
									}
									value={
										userSelections.max !== undefined ? userSelections.max : ''
									}
								/>
							</div>
						</FormControl>

						<FormControl style={{ width: '20%' }}>
							<Button
								style={{ margin: 'auto 0' }}
								variant="contained"
								disabled={disableOK}
								onClick={setConstraint}
							>
								OK
							</Button>
						</FormControl>
					</FormGroup>

					<DialogContentText style={{ marginTop: '10px' }}>
						{t('View')}
					</DialogContentText>

					{props.staff
						.slice(1)
						.filter((team) => team.active === true)
						.map((team) => {
							return (
								<div key={team.bagID}>
									<Typography>{team.name}</Typography>
									<ConstraintCards
										activeConstraint={activeConstraint}
										bagID={team.bagID}
										constraints={team.constraints}
										setUserSelections={setUserSelections}
										setActiveConstraint={setActiveConstraint}
										removeConstraint={removeConstraint}
									/>
								</div>
							);
						})}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							props.setOpen(false);
							clearSelections();
						}}
						color="primary"
						variant="contained"
					>
						{String(t('Close'))}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
}
