import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	MenuItem,
	InputLabel,
	Checkbox,
} from '@mui/material';
import { SPECIALISATION_STR, PROFESSION_STR } from '../../models/Models';
import { TField, TSelect } from '../Common/Common';
import { useTranslation } from 'react-i18next';
import { StaffDialogProps } from './types';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

export const StaffDialog = (props: StaffDialogProps) => {
	const { t } = useTranslation();

	const [state, setState] = useState({
		staffID: props.staff.staffID,
		name: props.staff.name,
		profession: props.staff.profession,
		specialisation: props.staff.specialisation,
		senior: props.staff.senior,
		locked: props.staff.locked,
		unavailable: !props.staff.available,
		canSave: false,
	});

	/** Re-apply props to state */
	const resetState = () => {
		setState({
			...state,
			name: props.staff.name,
			profession: props.staff.profession,
			specialisation: props.staff.specialisation,
			locked: props.staff.locked,
			unavailable: !props.staff.available,
		});
	};

	// const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>, column: 'profession' | 'specialisation') => {
	// onChange?: SelectInputProps<T>['onChange'];
	const handleSelectChange = (
		event: SelectChangeEvent<unknown>,
		column: 'profession' | 'specialisation'
	) => {
		const newValue: number = Number(event.target.value);
		if (newValue !== state[column])
			setState({ ...state, [column]: newValue, canSave: true });
	};

	const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newValue: string = String(event.target.value);
		if (newValue !== state.name)
			setState({ ...state, name: newValue, canSave: true });
	};

	useEffect(() => {
		resetState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.staff]);

	return (
		<Dialog
			open={props.open}
			onClose={(event, reason) => {
				if (reason === 'escapeKeyDown') props.closeWindow();
			}}
		>
			<DialogTitle>{t('Edit Staff')}</DialogTitle>

			<DialogContent>
				<DialogContentText>{t('staffDialogDescription')}</DialogContentText>

				<TField
					defaultValue={state.name}
					label={t('Name')}
					fullWidth={true}
					onChange={(e) => handleNameChange(e)}
				/>
				<InputLabel id="demo-simple-select-label">{t('Profession')}</InputLabel>
				<TSelect
					value={state.profession}
					fullWidth={true}
					onChange={(e) => handleSelectChange(e, 'profession')}
				>
					{PROFESSION_STR.map((item, index) => (
						<MenuItem value={index} key={index}>
							{t(item)}
						</MenuItem>
					))}
				</TSelect>

				<InputLabel>{t('Specialisation')}</InputLabel>
				<TSelect
					value={state.specialisation}
					fullWidth={true}
					onChange={(e) => handleSelectChange(e, 'specialisation')}
				>
					{SPECIALISATION_STR.map((item, index) => (
						<MenuItem value={index} key={index}>
							{t(item)}
						</MenuItem>
					))}
				</TSelect>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<InputLabel>{t('Lock')}</InputLabel>
					<Checkbox
						disabled={props.lockDisabled}
						checked={state.locked}
						onChange={(event) => {
							setState({
								...state,
								locked: event.target.checked,
								canSave: true,
							});
							props.setUnavailableDisabled();
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<InputLabel>{t('Unavailable')}</InputLabel>
					<Checkbox
						disabled={props.unavailableDisabled}
						checked={state.unavailable}
						onChange={(event) => {
							setState({
								...state,
								unavailable: event.target.checked,
								canSave: true,
							});
							props.setLockDisabled();
						}}
					/>
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					onClick={() => {
						resetState();
						props.closeWindow();
					}}
					color="primary"
					variant="contained"
				>
					{t('Cancel')}
				</Button>

				<Button
					disabled={!state.canSave}
					color="primary"
					onClick={() => {
						props.updateStaff({
							...props.staff,
							staffID: state.staffID,
							name: state.name,
							profession: state.profession,
							specialisation: state.specialisation,
							senior: state.senior,
							locked: state.locked,
							available: !state.unavailable,
						});
						setState({ ...state, canSave: false });
					}}
					variant="contained"
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};
