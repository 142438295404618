import {
	Operation,
	OperationType,
	StaffType,
	Staff,
	PROFESSION_STR,
	PROFESSION_CONVERTER,
	SPECIALISATION_STR,
	SPECIALISATION_CONVERTER,
} from '../models/Models';
import { BagOperation, ServerBag, ServerStaff } from '../models/ServerModels';
import axios from 'axios';
import { LunchbreakType } from '../Stepper/OptimizeSchedule/types';
import { isOverlapping } from '../Stepper/OptimizeSchedule/ScheduleUtils';

/**
 * Uses POST requets to backend to solve a bin-packing problem.
 * This function will create a payload, POST and then re-assemble objects so that it fits the state-variables and be using callback to set the variables.
 * @param operations OperationType, array
 * @param maxDurations array of maxdurations
 */
export async function binPack(
	operations: Array<OperationType>,
	maxDurations: Array<number>
) {
	const allOperations = operations.flatMap((item) => item.operations);

	const getData = (room: number, response: any) => {
		return response.data[room].operations.map((item: BagOperation) => {
			const itemName = allOperations.find((op) => op.operationId === item.opID);
			const name = itemName ? itemName.name : '';

			return new Operation({
				// conversion to Operation
				name: name,
				operationId: item.opID,
				preOpTime: item.preOpTime,
				opTime: item.opTime,
				postOpTime: item.postOpTime,
				room: item.room,
				start: 0, // replace this!
			});
		});
	};

	/* Creating payload */
	const payload = {
		operations: allOperations.map((op: Operation) => {
			return {
				opID: op.operationId,
				preOpTime: op.preOpTime,
				opTime: op.opTime,
				postOpTime: op.postOpTime,
				room: op.room,
			};
		}),
		bags: maxDurations.map((item, index) => {
			return {
				bagID: index + 1,
				maxDuration: item,
				bagValue: 0,
				operations: [],
			};
		}),
	};

	return axios
		.post('/MKPoptimize', payload)
		.then((response) => {
			const newData: Array<OperationType> = operations.map((row, index) => {
				if (index === 0) return { ...row, operations: [] };
				else return { ...row, operations: getData(index - 1, response) };
			});

			const taken = newData.flatMap<Operation>((item) => item.operations);
			const leftOver: Array<Operation> = [];

			allOperations.forEach((op: Operation) => {
				if (!taken.find((t) => t.operationId === op.operationId))
					leftOver.push(op);
			});
			newData[0].operations = leftOver;

			/* Calculating duration */
			newData.forEach((row: OperationType, index) => {
				/* Sequencing */
				// calculating duration
				for (let opIndex = 1; opIndex < row.operations.length; opIndex++) {
					const prev = row.operations[opIndex - 1];
					newData[index].operations[opIndex].start =
						prev.start + prev.duration + 15;
				}

				// assigning duration
				newData[index].duration = row.operations.reduce<number>(
					(acc: number, op: Operation) => acc + op.duration,
					0
				);
			});

			return newData;
		})
}

export function binPackStaff(staff: Array<StaffType>) {
	const allStaff: Array<Staff> = staff.flatMap((item) => item.data);

	// Staff that are available and non-locked
	const availableStaff: Array<Staff> = allStaff.filter(
		(item) => item.available && !item.locked
	);

	const getTeamData = (bagID: number, response: any): Array<Staff> => {
		return response.data
			.find((element: ServerBag) => element.bagID === bagID)
			.bagStaff.map((staff: ServerStaff) => {
				const fStaff = allStaff.find(
					(item) => item.staffID === Number(staff.staffID)
				);
				return {
					staffID: Number(staff.staffID),
					name: fStaff ? fStaff.name : '',
					profession: PROFESSION_CONVERTER[staff.profession],
					specialisation: SPECIALISATION_CONVERTER[staff.specialisation],
					senior: staff.senior,
					locked: fStaff ? fStaff.locked : false,
					available: true,
				};
			});
	};

	/* Creating payload */
	const payload = {
		staff: availableStaff.map((staff: Staff) => {
			return {
				staffID: String(staff.staffID),
				profession: PROFESSION_STR[staff.profession],
				specialisation: SPECIALISATION_STR[staff.specialisation],
				senior: staff.senior,
			};
		}),
		bags: staff
			.slice(1)
			.filter((team) => team.active === true)
			.map((team) => {
				const result: any = {
					bagID: team.bagID,
					bagStaff: team.data
						.filter((st) => st.locked)
						.map((staff) => {
							return {
								staffID: String(staff.staffID),
								profession: PROFESSION_STR[staff.profession],
								specialisation: SPECIALISATION_STR[staff.specialisation],
								senior: staff.senior,
							};
						}),
					bagValue: 0,
				};

				result['staffConstraint'] = team.constraints.find(
					(constraint) => constraint.name === 'Staff'
				);			
				result['nurseConstraint'] = team.constraints.find(
					(constraint) => constraint.name === 'Nurses'
				);
				result['doctorConstraint'] = team.constraints.find(
					(constraint) => constraint.name === 'Doctors'
				);
				result['seniorityConstraint'] = team.constraints.find(
					(constraint) => constraint.name === 'Seniors'
				);

				return result;
			}),
	};

	/* Fetching */
	return axios
		.post('/staff', payload)
		.then((response) => {
			const newData = staff.map((team, teamIndex) => {
				if (teamIndex === 0) return { ...team, data: [] };
				else
					return {
						...team,
						data: team.active
							? getTeamData(team.bagID, response).reverse()
							: [],
					};
			});

			/* Not assigned staff */
			const assignedStaff = newData.flatMap((item) => item.data);
			// Look for non-assigned staff or locked staff
			const notAssignedStaff: Array<Staff> = allStaff.reduce<Array<Staff>>(
				(acc, st) => {
					if (
						!assignedStaff.find((st2) => st2.staffID === st.staffID) ||
						!st.available
					) {
						acc.push(st);
					}
					return acc;
				},
				[]
			);

			newData[0].data = notAssignedStaff;
			return newData;
		})
		.catch((error) => {
			const err = new Error();
			if (error.response && error.response.status === 422) {
				err.message = error.response.data;
				err.name = 'Model infeasible';
			} else {
				err.message = error;
			}
			throw err;
		});
}

export const STARTOFDAY = 480; // offset

export interface ScheduleOptimize {
	scheduledOps: Array<OperationType>;
	tracks: Array<Tracks>;
	messageType: string;
	message: string;
}

/**
 * A helper function that uses POST request to schedule operations.
 * @param operations OperationType incoming data, transformed into POST request payload
 * @param numTeams number of teams active during scheduling
 null */
export function scheduleOptimizer(
	operations: Array<OperationType>,
	numTeams: number,
	tPrep: number = 15,
	lunchbreak: LunchbreakType | undefined
): Promise<ScheduleOptimize> {
	const allToBeScheduled = operations.flatMap((row) => row.operations);
	const payload = operations.flatMap((ops, index) => {
		const tmpOps = ops.operations.map((op) => {
			const pObject = {
				opID: op.operationId,
				room: ops.name,
				preOpTime: op.preOpTime,
				opTime: op.opTime,
				postOpTime: op.postOpTime,
			};
			if (op.locked) {
				return {
					...pObject,
					startHour: Math.floor(op.start / 60) + 8,
					startMinute: op.start % 60,
				};
			}
			return pObject;
		});

		/** Check for lunch breaks: adding dummy operations */
		if (lunchbreak?.enabled && lunchbreak.value.start < lunchbreak.value.end) {
			const opDuration =
				lunchbreak.value.end.getHours() * 60 +
				lunchbreak.value.end.getMinutes() -
				(lunchbreak.value.start.getHours() * 60 +
					lunchbreak.value.start.getMinutes());
			tmpOps.push({
				opID: `H${index}`,
				room: ops.name,
				preOpTime: 1,
				opTime: opDuration,
				postOpTime: 1,
				startHour: lunchbreak.value.start.getHours(),
				startMinute: lunchbreak.value.end.getMinutes(),
			});
		}

		return tmpOps;
	});

	return axios
		.post(`/day?teams=${numTeams}&tPrep=${tPrep}`, payload)
		.then((resp) => {
			/** Creating an empty copy */
			const newData: Array<OperationType> = operations.map((row) => {
				return { ...row, operations: [] };
			});

			/** Transforming data into copy (newData) */
			resp.data.forEach((scheduledOp: any) => {
				const {
					opID,
					room,
					startHour,
					startMinute,
					preOpTime,
					opTime,
					postOpTime,
				} = scheduledOp;

				const fOp = allToBeScheduled.find((op) => op.operationId === opID);
				const roomIndex = operations.findIndex((r) => r.name === room);
				if (fOp && roomIndex !== -1) {
					const opName = fOp.name;
					const newOp = new Operation({
						name: opName,
						operationId: opID,
						preOpTime: preOpTime,
						opTime: opTime,
						postOpTime: postOpTime,
						room: room,
						start: startHour * 60 + startMinute - STARTOFDAY,
					});
					// Check if the operations was locked
					newOp.locked = fOp.locked;

					newData[roomIndex].operations.push(newOp);
				}
			});

			newData.forEach((room, roomIndex) => {
				newData[roomIndex].operations = room.operations.sort(
					(a, b) => a.start - b.start
				);
			});

			return {
				scheduledOps: newData,
				tracks: setAllTracks(newData),
				messageType: 'ok',
				message: 'Operations successfully scheduled',
			};
		});
}

export type Tracks = {
	maxTracks: number;
	tracks: Array<number>;
};

export const setAllTracks = (ops: Array<OperationType>): Array<Tracks> => {
	const tracksCopy: Array<Tracks> = ops.map((item) => {
		return {
			maxTracks: 1,
			tracks: item.operations.map(() => -1),
		};
	});

	ops.forEach((room, roomIndex) => {
		const tmpTracks: Tracks = { maxTracks: 1, tracks: [] };
		const placed: Array<Operation> = [];
		/** Placing each operation */
		room.operations.forEach((op) => {
			// Check for overlapping operations with the SAME trackNr
			// Getting a list of operations that fulfills above criteria

			// overlapping candidates
			const candidates: Array<number> = [];
			placed.forEach((candidate, cInd) => {
				if (
					candidate.operationId !== op.operationId &&
					isOverlapping(candidate, op)
				)
					candidates.push(tmpTracks.tracks[cInd]);
			});

			let trackNr = 0;
			let spotFound = false;
			while (!spotFound) {
				if (candidates.includes(trackNr)) trackNr = trackNr + 1;
				else spotFound = true;
			}
			tmpTracks.tracks.push(trackNr);
			placed.push(op);
		});
		tracksCopy[roomIndex] = tmpTracks;
		tracksCopy[roomIndex].maxTracks =
			tmpTracks.tracks.length === 0
				? 1
				: Math.max(...tracksCopy[roomIndex].tracks) + 1;
	});

	return tracksCopy;
};

export function assignTeams(ops: Array<OperationType>, teams: Array<string>) {
	const payload = {
		teams: teams,
		operations: ops.flatMap((room) => {
			return room.operations.map((op) => {
				return {
					opID: op.operationId,
					preOpTime: op.preOpTime,
					opTime: op.opTime,
					postOpTime: op.postOpTime,
					room: room.name,
					startHour: Math.floor(op.start / 60),
					startMinute: op.start % 60,
				};
			});
		}),
	};

	return axios
		.post('/teamassignment', payload)
		.then((resp) => {
			const newOps = [...ops];

			resp.data.forEach((item: any) => {
				const room: string = item.room;
				const opID = item.opID;

				const roomIndex = newOps.findIndex((r) => r.name === room);
				if (roomIndex >= 0) {
					const opIndex = newOps[roomIndex].operations.findIndex(
						(op) => op.operationId === opID
					);
					if (opIndex >= 0) {
						const selectedOp = newOps[roomIndex].operations[opIndex];
						selectedOp.preOpTeam = item.preOpTeam;
						selectedOp.opTeam = item.opTeam;
						selectedOp.postOpTeam = item.postOpTeam;
					}
				}
			});
			return newOps;
		});
}
