import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Start: React.FunctionComponent = () => {
	return (
		<div>
			<h1 style={{ textAlign: 'center' }}>Welcome to HIAS demo!</h1>
			<div style={{ textAlign: 'center' }}>
				<Button
					variant="contained"
					color="secondary"
					style={{ margin: 'auto' }}
					component={Link}
					to="/schedule"
				>
					Start
				</Button>
			</div>
		</div>
	);
};

export default Start;
