import {
	Operation,
	Profession,
	Specialisation,
	StaffType,
	OperationType,
} from '../models/Models';
import { PresetConstraint } from '../providers/ConstraintsProvider';

export const fetchOperations = () => {
	/**
	 * Generates Patients, at the moment this will substitute for backend fetching
	 */
	const result: Array<OperationType> = [
		{
			name: 'Surgical cases',
			operations: [
				new Operation({
					name: 'Ulrika Andersson',
					operationId: 'O1',
					preOpTime: 35,
					opTime: 30,
					postOpTime: 45,
					start: 0,
				}),
				new Operation({
					name: 'Hans Eriksson',
					operationId: 'O2',
					preOpTime: 40,
					opTime: 90,
					postOpTime: 15,
					start: 0,
				}),
				new Operation({
					name: 'Karin Andersson',
					operationId: 'O3',
					preOpTime: 30,
					opTime: 20,
					postOpTime: 50,
					start: 0,
				}),
				new Operation({
					name: 'Sofia Lind',
					operationId: 'O4',
					preOpTime: 60,
					opTime: 80,
					postOpTime: 44,
					start: 0,
				}),
				new Operation({
					name: 'Peter Bergström',
					operationId: 'O5',
					preOpTime: 45,
					opTime: 40,
					postOpTime: 45,
					start: 0,
				}),
				new Operation({
					name: 'Kristina Jakobsson',
					operationId: 'O6',
					preOpTime: 80,
					opTime: 60,
					postOpTime: 80,
					start: 0,
				}),
				new Operation({
					name: 'Carl Lundqvist',
					operationId: 'O7',
					preOpTime: 40,
					opTime: 45,
					postOpTime: 40,
					start: 0,
				}),
				new Operation({
					name: 'Anna Lundberg',
					operationId: 'O8',
					preOpTime: 50,
					opTime: 85,
					postOpTime: 65,
					start: 0,
				}),
				new Operation({
					name: 'Stig Wallin',
					operationId: 'O9',
					preOpTime: 80,
					opTime: 120,
					postOpTime: 30,
					start: 0,
				}),
				new Operation({
					name: 'Göran Lindberg',
					operationId: 'O10',
					preOpTime: 30,
					opTime: 30,
					postOpTime: 30,
					start: 0,
				}),
				new Operation({
					name: 'Jan Lindqvist',
					operationId: 'O11',
					preOpTime: 31,
					opTime: 32,
					postOpTime: 33,
					start: 0,
				}),
				new Operation({
					name: 'Katarina Olsson',
					operationId: 'O12',
					preOpTime: 43,
					opTime: 87,
					postOpTime: 80,
					start: 0,
				}),
				new Operation({
					name: 'Viola Nisson',
					operationId: 'O13',
					preOpTime: 90,
					opTime: 80,
					postOpTime: 30,
					start: 0,
				}),
				new Operation({
					name: 'Gustav Holm',
					operationId: 'O14',
					preOpTime: 45,
					opTime: 80,
					postOpTime: 45,
					start: 0,
				}),
				new Operation({
					name: 'Tommy Lindgren',
					operationId: 'O15',
					preOpTime: 34,
					opTime: 90,
					postOpTime: 70,
					start: 0,
				}),
			],
			duration: 0,
		},
		{ name: 'Room 1', operations: [], duration: 0 },
		{ name: 'Room 2', operations: [], duration: 0 },
		{ name: 'Room 3', operations: [], duration: 0 },
	];
	result[0].duration = result[0].operations.reduce<number>(
		(acc: number, item: Operation) => acc + item.duration,
		0
	);

	return result;
};

export const fetchStaff = (): Array<StaffType> => {
	/**
	 * Generates Staff, at the moment this will substitute for backend fetching
	 */
	return [
		{
			bagID: 0,
			name: 'Staff',
			data: [
				{
					staffID: 0,
					name: 'Linnéa Andersson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GENERAL,
					senior: true,
					locked: false,
					available: false,
				},
				{
					staffID: 1,
					name: 'Ellen Larsson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.NEUROLOGICAL,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 2,
					name: 'Malin Olsson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GYNECOLOGIC_ONCOLOGY,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 3,
					name: 'Patrik Öberg',
					profession: Profession.NURSE,
					specialisation: Specialisation.UROLOGY,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 4,
					name: 'Lucas Månsson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.THORACIC,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 5,
					name: 'William Axelsson',
					profession: Profession.NURSE,
					specialisation: Specialisation.THORACIC,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 6,
					name: 'Björn Olofsson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.NEUROLOGICAL,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 7,
					name: 'Sara Ahmed',
					profession: Profession.NURSE,
					specialisation: Specialisation.GENERAL,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 8,
					name: 'Lisbeth Olsson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.PEDIATRIC,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 9,
					name: 'William Jansson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GENERAL,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 10,
					name: 'Sebastian Berg',
					profession: Profession.NURSE,
					specialisation: Specialisation.PEDIATRIC,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 11,
					name: 'Susanne Bergström',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.GYNECOLOGIC_ONCOLOGY,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 12,
					name: 'Anita Jansson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GENERAL,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 13,
					name: 'Anna Olsson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.PEDIATRIC,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 14,
					name: 'Ellinor Sjögren',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.GENERAL,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 15,
					name: 'Olof Håkansson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GENERAL,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 16,
					name: 'Mats Karlsson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GENERAL,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 17,
					name: 'Karl Eriksson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.GENERAL,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 18,
					name: 'Mikael Blomqvist',
					profession: Profession.NURSE,
					specialisation: Specialisation.NEUROLOGICAL,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 19,
					name: 'Kent Danielsson',
					profession: Profession.NURSE,
					specialisation: Specialisation.OTOLARYNGOLOGY,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 20,
					name: 'Kristina Norberg',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.VASCULAR,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 21,
					name: 'Anton Johansson',
					profession: Profession.DOCTOR,
					specialisation: Specialisation.NEUROLOGICAL,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 22,
					name: 'Mikael Larsson',
					profession: Profession.NURSE,
					specialisation: Specialisation.GYNECOLOGIC_ONCOLOGY,
					senior: false,
					locked: false,
					available: true,
				},
				{
					staffID: 23,
					name: 'Åsa Magnusson',
					profession: Profession.NURSE,
					specialisation: Specialisation.PEDIATRIC,
					senior: true,
					locked: false,
					available: true,
				},
				{
					staffID: 24,
					name: 'Christoffer Åberg',
					profession: Profession.NURSE,
					specialisation: Specialisation.ORTHOPAEDIC,
					senior: true,
					locked: false,
					available: true,
				},
			],
			active: true,
			constraints: [],
		},
		{ bagID: 1, name: 'Team A', data: [], active: true, constraints: [] },
		{ bagID: 2, name: 'Team B', data: [], active: true, constraints: [] },
		{ bagID: 3, name: 'Team C', data: [], active: true, constraints: [] },
		{ bagID: 4, name: 'Team D', data: [], active: true, constraints: [] },
		{ bagID: 5, name: 'Team E', data: [], active: false, constraints: [] },
		{ bagID: 6, name: 'Team F', data: [], active: false, constraints: [] },
		{ bagID: 7, name: 'Team G', data: [], active: false, constraints: [] },
		{ bagID: 8, name: 'Team H', data: [], active: false, constraints: [] },
	];
};

export const fetchPresetConstraints = (): Array<PresetConstraint> => {
	return [
		{
			presetName: 'None',
			teamConstraint: [],
		},
		{
			presetName: 'Standard',
			teamConstraint: [{ name: 'Staff', min: 3, max: 5 }],
		},
		{
			presetName: 'Standard limit nurse',
			teamConstraint: [
				{ name: 'Staff', min: 3, max: 5 },
				{ name: 'Nurses', min: 1, max: 2 },
			],
		},
	];
};
