import { createTheme } from '@mui/material';
import { TEAMCOLORS as colors } from './models/Models';

export const APP_WIDTH = 1200;

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: colors[3].hex(),
		},
		secondary: {
			main: colors[1].hex(),
		},
	},
});

export const lightTheme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: colors[0].hex(),
		},
		secondary: {
			main: colors[1].hex(),
		},
	},
});
