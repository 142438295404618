import { useState } from 'react';
import { Draggable } from 'react-smooth-dnd';
import '../stepper.scss';
import OperationCard from './OperationCard';
import { Operation } from '../../models/Models';
import { OperationDialog } from './OperationDialog';
import { minutesToHM, Snackbar, DnDContainer } from '../Common/Common';
import { LabelChanger } from '../Common/LabelChanger';
import { binPack } from '../../api/ApiUtils';
import { useTranslation } from 'react-i18next';
import {
	DurationProps,
	OperationProps,
	OperationSelectionState,
} from './types';
import {
	IconButton,
	Typography,
	Tooltip,
	TextField,
	Button,
	Paper,
	CircularProgress,
} from '@mui/material';
import { AddBox, IndeterminateCheckBox } from '@mui/icons-material';

/**
 * Displays a Spinner element
 */
const Spinner = () => {
	const { t } = useTranslation();
	return (
		<div style={{ textAlign: 'center', marginTop: '1rem' }}>
			<CircularProgress size="5rem" color="secondary" />
			<Typography variant="subtitle2">{t('Loading')} ...</Typography>
		</div>
	);
};

const Duration = (props: DurationProps) => {
	const { t } = useTranslation();
	const { duration, maxDuration } = props;
	const { hours, minutes } = minutesToHM(duration);

	let percentage = '';
	let textColor = '#999';
	if (maxDuration) {
		if (duration > maxDuration) textColor = 'red';
		percentage = ` / ${((duration / maxDuration) * 100.0).toFixed(1)} %`;
	}

	return (
		<Tooltip
			className="tool-tip-time"
			title={`${hours} ${t('hours')}, ${minutes} ${t('minutes')}`}
			arrow
		>
			<Typography
				style={{ color: textColor, width: 'fit-content' }}
				align="left"
			>
				{`Σ ${duration} ${percentage}`}
			</Typography>
		</Tooltip>
	);
};

export default function OperationSelection(props: OperationProps) {
	const { t } = useTranslation();

	const [state, setState] = useState<OperationSelectionState>({
		loading: false,
		optimized: false,
		selectedOp: props.operations[0].operations[0], // first operation
		roomIndex: 0,
		opIndex: 0,
		showWindow: false,
		maxDuration: props.operations.map(() => 480),
		showForm: props.operations.map(() => false),
		snackBar: {
			open: false,
			message: '',
			messageType: 'ok',
		},
		optimizeDisabled: false,
	});

	/**
	 * applyDrag
	 * @param arr Operation[] array of Operations
	 * @param dragResult object used by third party library
	 * @param column index for operations (room index)
	 */
	const applyDrag = (arr: Operation[], dragResult: any, column: number) => {
		const { removedIndex, addedIndex, payload } = dragResult;
		if (removedIndex === null && addedIndex === null) return arr;

		const result = [...arr];
		let itemToAdd = payload;

		if (removedIndex !== null) itemToAdd = result.splice(removedIndex, 1)[0];

		if (addedIndex !== null) result.splice(addedIndex, 0, itemToAdd);

		/** Sequencing result array */
		if (result.length > 0) {
			result[0].start = 0;
			for (let opIndex = 1; opIndex < result.length; opIndex++) {
				const prev = result[opIndex - 1];
				result[opIndex].start = prev.start + prev.duration + 15;
			}
		}

		const copy = [...props.operations];
		copy[column].operations = result;
		copy[column].duration = result.reduce<number>(
			(acc, item) => acc + item.duration,
			0
		); // re-calculates
		props.setOperations(copy);
		setState({ ...state, optimizeDisabled: false });
	};

	/**
	 * Sets the new maxduration at roomIndex
	 * @param roomIndex number, index of the room
	 * @param newValue number, new max duration value
	 */
	const handleMaxDuration = (roomIndex: number, newValue: number) => {
		if (newValue > 0) {
			const copy = [...state.maxDuration];
			copy[roomIndex] = newValue;
			setState({ ...state, maxDuration: copy });
		}
	};

	/**
	 * Opens the OperationDialog window
	 * @param op Operation object to be send to OperationDialog
	 * @param roomIndex number
	 * @param opIndex number
	 */
	const handleOpenWindow = (
		op: Operation,
		roomIndex: number = 0,
		opIndex: number = 0
	) => {
		setState({
			...state,
			showWindow: true,
			selectedOp: op,
			roomIndex,
			opIndex,
		});
	};

	/**
	 * Edits the new Operation and closes the Dialog window
	 * @param op Operation new Operation to be changed
	 * @param roomIndex number
	 * @param opIndex number
	 */
	const handleSaveAndCloseWindow = (
		op: Operation,
		roomIndex: number = 0,
		opIndex: number = 0
	) => {
		const copy = [...props.operations];
		copy[roomIndex].duration +=
			op.duration - copy[roomIndex].operations[opIndex].duration; // updating duration
		copy[roomIndex].operations[opIndex] = op;

		props.setOperations(copy);
		setState({
			...state,
			showWindow: false,
			snackBar: {
				...state.snackBar,
				open: true,
				message: `${op.operationId}, ${op.name} ${t('updated')}.`,
				messageType: 'ok',
			},
		});
	};

	/**
	 * Changes the name of the container
	 * @param newValue string new value from textField
	 * @param index number, index from where the container name is located
	 */
	const changeName = (newValue: string, index: number = 0) => {
		const copy = [...props.operations];
		copy[index].name = newValue;
		props.setOperations(copy);
		setState({ ...state, showForm: state.showForm.map(() => false) });
	};

	return (
		<>
			<OperationDialog
				operation={state.selectedOp}
				open={state.showWindow}
				closeWindow={() => setState({ ...state, showWindow: false })}
				editOperation={(op) =>
					handleSaveAndCloseWindow(op, state.roomIndex, state.opIndex)
				}
			/>

			<div
				className="page-grid"
				style={{
					gridTemplateColumns: '220px repeat(4, 1fr) min-content',
				}}
			>
				<div key="SurgicalCases">
					<LabelChanger
						namesData={{
							names: props.operations.map((item) => item.name),
							currentIndex: 0,
						}}
						show={state.showForm[0]}
						cancel={() =>
							setState({ ...state, showForm: state.showForm.map(() => false) })
						}
						setName={(name) => changeName(name, 0)}
						toggleChange={() => {
							setState({
								...state,
								showForm: state.showForm.map((x, formIndex) => 0 === formIndex),
							});
						}}
					/>
					<Duration duration={props.operations[0].duration} />
					<Paper
						className="card-group"
						style={{ height: 'calc(60vh + 22px)' }}
						elevation={0}
					>
						<DnDContainer
							getChildPayload={(i) => props.operations[0].operations[i]}
							onDrop={(e) => applyDrag(props.operations[0].operations, e, 0)}
						>
							{props.operations[0].operations.map((patient, cardIndex) => {
								return (
									<Draggable key={`${cardIndex}-SurgicalCases-${patient.name}`}>
										<OperationCard
											operation={patient}
											setOpenWindow={(op) => handleOpenWindow(op, 0, cardIndex)}
										/>
									</Draggable>
								);
							})}
						</DnDContainer>
					</Paper>
				</div>
				{props.operations.slice(1).map((item, index) => (
					<div key={item.name}>
						<LabelChanger
							namesData={{
								names: props.operations.map((item) => item.name),
								currentIndex: index + 1,
							}}
							show={state.showForm[index + 1]}
							cancel={() =>
								setState({
									...state,
									showForm: state.showForm.map(() => false),
								})
							}
							setName={(name) => changeName(name, index + 1)}
							toggleChange={() => {
								setState({
									...state,
									showForm: state.showForm.map(
										(x, formIndex) => index + 1 === formIndex
									),
								});
							}}
						/>
						<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
							<Duration
								duration={item.duration}
								maxDuration={state.maxDuration[index]}
							/>
							<div style={{ maxWidth: '60px', marginLeft: 'auto' }}>
								<Tooltip
									title={`${t('Max-duration')}: ${
										minutesToHM(state.maxDuration[index]).hours
									} ${t('hours')}, ${
										minutesToHM(state.maxDuration[index]).minutes
									} ${t('minutes')}`}
									arrow
								>
									<TextField
										className="text-field"
										id="standard-number"
										type="number"
										color="primary"
										InputProps={{
											inputProps: { min: 1, style: { padding: '0px' } },
										}}
										InputLabelProps={{
											shrink: true,
										}}
										onChange={(e) =>
											handleMaxDuration(
												index,
												Number((e.target as HTMLInputElement).value)
											)
										}
										defaultValue={state.maxDuration[index]}
									/>
								</Tooltip>
							</div>
						</div>
						<Paper
							className="card-group"
							style={{ height: 'calc(60vh + 22px)' }}
							elevation={0}
						>
							{state.loading ? (
								<Spinner />
							) : (
								<DnDContainer
									getChildPayload={(i) => item.operations[i]}
									onDrop={(e) => applyDrag(item.operations, e, index + 1)}
								>
									{item.operations.map((patient, cardIndex) => {
										return (
											<Draggable
												key={`${cardIndex}-${index + 1}-${patient.name}`}
											>
												<OperationCard
													operation={patient}
													setOpenWindow={(op) =>
														handleOpenWindow(op, index + 1, cardIndex)
													}
												/>
											</Draggable>
										);
									})}
								</DnDContainer>
							)}
						</Paper>
					</div>
				))}
				{Array.from('0'.repeat(5 - props.operations.length)).map(
					(_item, index) => (
						<div key={index}>
							<LabelChanger
								color="rgba(220, 220, 220, 1)"
								namesData={{
									names: ['Room ' + (props.operations.length + index)],
									currentIndex: 0,
								}}
								show={false}
								cancel={() => {}}
								setName={() => {}}
								toggleChange={() => {}}
							/>
							<div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
								<Duration duration={0} maxDuration={0} />
								<div style={{ maxWidth: '60px', marginLeft: 'auto' }}>
									<TextField
										color="primary"
										InputProps={{
											inputProps: {
												style: {
													padding: '0px',
													backgroundColor: 'rgba(220, 220, 220, 0.3)',
												},
											},
										}}
										InputLabelProps={{ shrink: true }}
										onChange={() => {}}
										// defaultValue={0}
										disabled={true}
									/>
								</div>
							</div>
							<Paper
								className="card-group"
								style={{
									height: 'calc(60vh + 20px)',
									border: '2px dashed #ccc',
									backgroundColor: 'rgba(220, 220, 220, 0.3)',
								}}
								elevation={0}
							>
								<DnDContainer>
									<></>
								</DnDContainer>
							</Paper>
						</div>
					)
				)}
				<div
					style={{ margin: 'auto', display: 'flex', flexDirection: 'column' }}
				>
					<Tooltip title={String(t('Add room'))} placement="right" arrow>
						<span>
							<IconButton
								onClick={() => {
									props.setOperations([
										...props.operations,
										{
											name: `Room ${props.operations.length}`,
											operations: [],
											duration: 0,
										},
									]);
								}}
								disabled={props.operations.slice(1).length >= 4}
							>
								<AddBox fontSize="large" />
							</IconButton>
						</span>
					</Tooltip>

					<Tooltip title={String(t('Remove room'))} placement="right" arrow>
						<span>
							<IconButton
								onClick={() => {
									props.setOperations([...props.operations.slice(0, -1)]);
								}}
								disabled={props.operations.slice(1).length <= 2}
							>
								<IndeterminateCheckBox fontSize="large" />
							</IconButton>
						</span>
					</Tooltip>
				</div>
			</div>
			<div style={{ textAlign: 'center' }}>
				<Tooltip title={String(t('ToolTipOptimizeOperations'))} arrow>
					<Button
						onClick={() => {
							setState({ ...state, loading: true });
							setTimeout(() => {
								// Adding delay to show spinner
								binPack(props.operations, state.maxDuration)
									.then((res) => {
										props.setOperations(res);
										setState({
											...state,
											snackBar: {
												open: true,
												message: `${t('SnackBarOptimizedOperations')}`,
												messageType: 'ok',
											},
											optimized: false,
										});
									})
									.catch((error) => {
										setState({
											...state,
											snackBar: {
												open: true,
												message: `${error.message}`,
												messageType: 'error',
											},
										});
									});
								setState({
									...state,
									loading: false,
									optimizeDisabled: true,
									optimized: true,
								});
							}, 2000);
						}}
						variant="contained"
						color="primary"
						disabled={state.loading || state.optimizeDisabled}
					>
						{t('Optimize')}
					</Button>
				</Tooltip>
			</div>

			<Snackbar
				messageType={state.snackBar.messageType}
				message={state.snackBar.message}
				open={state.snackBar.open}
				duration={6000}
				handleClose={() =>
					setState({ ...state, snackBar: { ...state.snackBar, open: false } })
				}
			/>
		</>
	);
}
