import axios from 'axios';
import Keycloak from 'keycloak-js';
import { useState } from 'react';

type AxiosConfigurationProps = {
	keycloak: Keycloak.KeycloakInstance;
};

axios.defaults.baseURL = 'https://model.sownderdev.se/model/optimize';

const RETRY_WAIT_TIME = 10000;

function AxiosConfiguration(props: AxiosConfigurationProps) {
	const [initialized, setInitialized] = useState(false);

	if (!initialized) {
		axios.interceptors.request.use(function (config) {
			if (config.headers !== undefined)
				config.headers['Authorization'] = `Bearer ${props.keycloak.token}`;

			return config;
		});

		axios.interceptors.response.use(
			function (response) {
				return response;
			},
			async function (error) {
				if (error.response && error.response.status === 401) {
					const retried = error.config.headers['Retry'] === 'true';
					error.config.headers['Retry'] = 'true';
					await new Promise((resolve) =>
						setTimeout(resolve, retried ? RETRY_WAIT_TIME : 0)
					);
					await props.keycloak.updateToken(0);
					error.config.headers['Authorization'] = props.keycloak.token;
					return axios.request(error.config);
				} else return Promise.reject(error);
			}
		);
		setInitialized(true);
	}

	return <></>;
}

export default AxiosConfiguration;
