import React, { useState, useEffect } from 'react';
import { StepButton, Button, Stepper, Step } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import CreateTeams from './CreateTeams/CreateTeams';
import OptimizeSchedule from './OptimizeSchedule/OptimizeSchedule';
import SelectedOperations from './SelectedOperations/SelectedOperations';
import { OperationType, StaffType } from '../models/Models';
import {
	fetchOperations,
	fetchStaff,
	fetchPresetConstraints,
} from './InitData';
import { useTranslation } from 'react-i18next';
import { ConstraintsProvider } from '../providers/ConstraintsProvider';

/** Number of Staffs  */
const NUMSTAFF = fetchStaff()[0].data.length;
/** Number of Operation  */
const NUMOPS = fetchOperations()[0].operations.length;

enum Steps {
	TEAMS,
	SELECTOPERATIONS,
	DNDOPERATIONS,
}

const HorizontalLinearStepper: React.FunctionComponent = () => {
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(0);
	const [opData, setOpData] = useState<Array<OperationType>>(fetchOperations());
	const [staff, setStaff] = useState<Array<StaffType>>(fetchStaff());
	const [numTeams, setNumTeams] = useState<number>(0);
	/** Calculates the new numTeams value, based on changes in staff */
	useEffect(() => {
		const newValue = staff.slice(1).reduce<number>((acc, item) => {
			if (item.data.length > 0) return acc + 1;
			return acc;
		}, 0);
		if (newValue !== numTeams) {
			setNumTeams(newValue);
		}
	}, [numTeams, staff]);

	const [cbFuncs, setCbFuncs] = useState<Array<() => void>>([]);

	const updateDnDOps = (selectiveOps: Array<OperationType>) => {
		setOpData([opData[0], ...selectiveOps]);
	};

	/**
	 * Displays one of three contents:
	 * 1. CreateTeam
	 * 2. Select Operations
	 * 3. Optimize Schedule
	 */
	const content = () => {
		// If at any point there are no rooms, reset to the default of 3 empty rooms
		if (opData.slice(1).length === 0) setOpData(fetchOperations());

		if (activeStep === 0)
			return (
				<CreateTeams
					staff={staff}
					setStaff={setStaff}
					addRefreshCallback={(cb: () => void) => {
						const arr = [...cbFuncs];
						arr.push(cb);
						setCbFuncs(arr);
					}}
				/>
			);
		else if (activeStep === 1)
			return (
				<SelectedOperations operations={opData} setOperations={setOpData} />
			);
		else {
			return (
				<OptimizeSchedule
					operations={opData
						.slice(1)
						.filter((room) => room.operations.length > 0)}
					setOperations={updateDnDOps}
					teams={staff
						.slice(1)
						.reduce<Array<{ name: string; active: boolean }>>(
							(acc, current) => {
								acc.push({
									name: current.name,
									active: current.data.length > 0,
								});
								return acc;
							},
							[]
						)}
				/>
			);
		}
	};

	/**
	 * Draws the buttons: Previous, Clear and Next
	 */
	const contentButton = () => {
		let backButtonProps: {};
		let forwardButtonProps: {};

		if (activeStep === Steps.TEAMS) {
			backButtonProps = { disabled: true };
			forwardButtonProps = {
				onClick: handleStep(Steps.SELECTOPERATIONS),
				disabled:
					staff
						.slice(1)
						.map((item) => item.data.length)
						.reduce((a, b) => a + b, 0) === 0,
			};
		} else if (activeStep === Steps.SELECTOPERATIONS) {
			backButtonProps = { onClick: handleStep(Steps.TEAMS) };
			forwardButtonProps = {
				onClick: handleStep(Steps.DNDOPERATIONS),
				disabled:
					opData
						.slice(1)
						.map((item) => item.operations.length)
						.reduce((a, b) => a + b, 0) === 0,
			};
		} else {
			backButtonProps = { onClick: handleStep(Steps.SELECTOPERATIONS) };
			forwardButtonProps = { disabled: true };
		}

		return (
			<div
				style={{
					textAlign: 'center',
					marginTop: '1rem',
					display: 'flex',
					justifyContent: 'space-between',
				}}
				className="button-group"
			>
				<Button {...backButtonProps} color="secondary">
					<KeyboardArrowLeft />
					{t('Back')}
				</Button>
				{renderClearButton()}
				<Button {...forwardButtonProps} color="secondary">
					{t('Next')}
					<KeyboardArrowRight />
				</Button>
			</div>
		);
	};

	/**
	 * Set the state variable: step based on input parameter
	 * @param step Steps
	 */
	const handleStep = (step: Steps) => () => {
		if (step !== activeStep) setActiveStep(step);
	};

	/**
	 * Draws a Clear button, depending on which part of the stepper is active
	 */
	const renderClearButton = () => {
		if (activeStep === 0) {
			return (
				<Button
					color="secondary"
					onClick={() => {
						/** Run all reset-callback functions for child components */
						setStaff(fetchStaff());
						cbFuncs.forEach((item) => item());
					}}
					disabled={NUMSTAFF === staff[0].data.length}
				>
					{t('Clear')}
				</Button>
			);
		} else if (activeStep === 1)
			return (
				<Button
					color="secondary"
					onClick={() => setOpData(fetchOperations())}
					disabled={NUMOPS === opData[0].operations.length}
				>
					Clear
				</Button>
			);
		else return null;
	};

	return (
		<ConstraintsProvider value={{ constraints: fetchPresetConstraints() }}>
			<div
				style={{
					margin: 'auto',
				}}
			>
				<Stepper
					style={{ marginBottom: '2rem' }}
					nonLinear
					activeStep={activeStep}
				>
					<Step key="1">
						<StepButton onClick={handleStep(0)}>{t('Create Teams')}</StepButton>
					</Step>
					<Step key="2">
						<StepButton onClick={handleStep(1)}>
							{t('Select Operations')}
						</StepButton>
					</Step>
					<Step key="3">
						<StepButton onClick={handleStep(2)}>
							{t('Optimize Schedule')}
						</StepButton>
					</Step>
				</Stepper>
				<div style={{ minHeight: '70vh' }}>{content()}</div>
				{contentButton()}
			</div>
		</ConstraintsProvider>
	);
};

export default HorizontalLinearStepper;
