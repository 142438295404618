import React, { useState } from 'react';
import { TextField, IconButton, Tooltip } from '@mui/material';
import { Clear, Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Typo } from './Common';

interface LabelChangerProps {
	namesData: { names: Array<string>; currentIndex: number };
	color?: string;
	show: boolean; // show either input element or header element
	cancel?: () => void; // handle for when user presses `cancel`
	setName?: (newValue: string) => void; // updates state-variable data
	toggleChange?: () => void; // toggles between input mode nad display header mode
}

/**
 * Will display either a Header or a TextInputfield
 * @param props LabelChangerProps
 */
export const LabelChanger = (props: LabelChangerProps) => {
	const { t } = useTranslation();
	const propsName = props.namesData.names[props.namesData.currentIndex]; // name to be shown

	const [state, setState] = useState({
		text: propsName,
		error: false,
	});

	/** Confirms input updates text state variable */
	const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, text: event.target.value });
	};

	/** Confirms input when pressing 'Enter'-key */
	const handleKeyPress = (event: React.KeyboardEvent<Object>) => {
		if (event.key === 'Enter') update();
	};

	/**
	 * Sets the state variables and updates the header
	 * Checks for duplicate entries
	 */
	const update = () => {
		if (
			props.namesData.names.find(
				(name, nIndex) =>
					nIndex !== props.namesData.currentIndex && name === state.text
			) === undefined
		) {
			props.setName && props.setName(state.text);
			setState({ ...state, error: false });
		} else setState({ ...state, error: true });
	};

	if (props.show) {
		return (
			<div>
				<TextField
					defaultValue={propsName}
					onChange={handleInput}
					onKeyPress={(event) => handleKeyPress(event)}
					style={{ width: '50%' }}
					error={state.error}
				/>

				<IconButton
					onClick={() => {
						setState({ ...state, error: false });
						props.cancel && props.cancel();
					}}
					size="small"
				>
					<Clear />
				</IconButton>

				<IconButton onClick={() => update()} size="small">
					<Check />
				</IconButton>
			</div>
		);
	} else {
		return (
			<Tooltip
				title={String(t('LabelChangerInstruction'))}
				arrow
				placement="top-start"
			>
				<div>
					<Typo
						variant="h5"
						style={{ color: props.color !== undefined ? props.color : 'black' }}
						onClick={props.toggleChange}
					>
						{propsName}
					</Typo>
				</div>
			</Tooltip>
		);
	}
};
