import React from 'react';
import { Paper, Tooltip, Typography } from '@mui/material';
import { DragHandle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OperationCardProps } from './types';

const gridStyle = {
	display: 'grid',
	gridTemplateColumns: 'auto min-content',
};

const PatientCard = (props: OperationCardProps) => {
	const { t } = useTranslation();

	/**
	 * User click input.
	 * uses callback function setOpenWindow to set the necessary state variables.
	 * see: SelectedOperations.tsx for more information
	 */
	const handleClick = () => {
		props.setOpenWindow(props.operation);
	};

	return (
		<Paper elevation={9} className="draggable-card" style={gridStyle}>
			<div onClick={() => handleClick()}>
				<Typography variant="caption">{props.operation.operationId}</Typography>
				<Typography variant="body2">{props.operation.name}</Typography>
				<Typography variant="caption">
					{props.operation.duration} {t('minutes')}
				</Typography>
			</div>

			<Tooltip
				title={String(t('DragInfoOperationCard'))}
				arrow
				placement="right"
			>
				<div className="dragHandleOp">
					<DragHandle style={{ color: '#ccc' }} />
				</div>
			</Tooltip>
		</Paper>
	);
};

export default PatientCard;
