import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	TextField,
	Checkbox,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import { TimePicker as MuiTimePicker, TimePickerProps } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
	OptimizeScheduleSettingsDialogProps,
	OptimizationSettings,
} from './types';
import { dateToHM } from './ScheduleUtils';
import './OptimizeSchedule.scss';

export const OptimizeScheduleSettingsDialog = (
	props: OptimizeScheduleSettingsDialogProps
) => {
	const { t } = useTranslation();

	const [optSettings, setOptSettings] = useState<OptimizationSettings>({
		...props.optSettings,
	});

	useEffect(() => {
		const start = new Date();
		start.setHours(12, 0);
		const end = new Date();
		end.setHours(13, 0);

		setOptSettings({
			...optSettings,
			lunch: { ...optSettings.lunch, value: { start: start, end: end } },
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validStartEnd =
		dateToHM(optSettings.lunch.value.start) <
		dateToHM(optSettings.lunch.value.end);

	return (
		<Dialog open={props.open}>
			<DialogTitle>Schedule Optimization Settings</DialogTitle>
			<DialogContent className="dialog-window">
				<Checkbox
					checked={optSettings.tPrep.enabled}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						setOptSettings({
							...optSettings,
							tPrep: { ...optSettings.tPrep, enabled: event.target.checked },
						})
					}
				/>
				<DescriptionText
					header={t('preparations')}
					body={t('preparations-description')}
					disabled={!optSettings.tPrep.enabled}
				/>
				<TextField
					fullWidth
					inputProps={{ min: 0 }}
					style={{ margin: 'auto 0' }}
					disabled={!optSettings.tPrep.enabled}
					type="number"
					label={t('minutes')}
					value={optSettings.tPrep.value}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setOptSettings({
							...optSettings,
							tPrep: {
								...optSettings.tPrep,
								value: Number(event.target.value),
							},
						});
					}}
				/>

				<Checkbox
					checked={optSettings.lunch.enabled}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						setOptSettings({
							...optSettings,
							lunch: { ...optSettings.lunch, enabled: event.target.checked },
						})
					}
				/>
				<DescriptionText
					header={t('lunch-break')}
					body={t('lunch-break-description')}
					disabled={!optSettings.lunch.enabled}
				/>
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: '1fr 1fr',
						gap: '1rem',
					}}
				>
					<TimePicker
						disabled={!optSettings.lunch.enabled}
						label={t('start')}
						value={optSettings.lunch.value.start}
						onChange={(date, kbInputV) => {
							if (date)
								setOptSettings({
									...optSettings,
									lunch: {
										...optSettings.lunch,
										value: { ...optSettings.lunch.value, start: date as Date },
									},
								});
						}}
						renderInput={(params) => <TextField {...params} />}
					/>
					<TimePicker
						disabled={!optSettings.lunch.enabled}
						label={t('end')}
						value={optSettings.lunch.value.end}
						onChange={(date, kbInputV) => {
							if (date)
								setOptSettings({
									...optSettings,
									lunch: {
										...optSettings.lunch,
										value: { ...optSettings.lunch.value, end: date as Date },
									},
								});
						}}
						renderInput={(params) => <TextField {...params} />}
					/>
					<Typography variant="caption" style={{ gridColumn: '1 / span 2' }}>
						{!validStartEnd ? (
							<i>
								<Typography variant="caption" style={{ color: 'red' }}>
									{t('lunch=break-error-description')}
								</Typography>
							</i>
						) : (
							''
						)}
					</Typography>
				</div>

				<Checkbox
					checked={optSettings.modelType.enabled}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						setOptSettings({
							...optSettings,
							modelType: {
								...optSettings.modelType,
								enabled: event.target.checked,
							},
						})
					}
				/>
				<DescriptionText
					header={t('model-type')}
					body={t('model-type-description')}
					disabled={!optSettings.modelType.enabled}
				/>
				<RadioGroup
					row
					value={optSettings.modelType.value}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setOptSettings({
							...optSettings,
							modelType: {
								...optSettings.modelType,
								value:
									event.target.value === 'continuous'
										? 'continuous'
										: 'discrete',
							},
						});
					}}
				>
					<FormControlLabel
						disabled={!optSettings.modelType.enabled}
						value="continuous"
						control={<Radio />}
						label={<Typography variant="body2">continuous</Typography>}
						style={{
							width: '50%',
							marginLeft: 0,
							marginRight: 0,
						}}
					/>
					<FormControlLabel
						disabled={!optSettings.modelType.enabled}
						value="discrete"
						control={<Radio />}
						label={<Typography variant="body2">discrete</Typography>}
						style={{
							width: '50%',
							marginLeft: 0,
							marginRight: 0,
						}}
					/>
				</RadioGroup>

				<Checkbox
					checked={optSettings.timeLimit.enabled}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						setOptSettings({
							...optSettings,
							timeLimit: {
								...optSettings.timeLimit,
								enabled: event.target.checked,
							},
						})
					}
				/>
				<DescriptionText
					header={t('time-limit')}
					body={t('time-limit-description')}
					disabled={!optSettings.timeLimit.enabled}
				/>
				<TextField
					fullWidth
					inputProps={{ min: 5, step: 5 }}
					disabled={!optSettings.timeLimit.enabled}
					value={optSettings.timeLimit.value}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
						setOptSettings({
							...optSettings,
							timeLimit: {
								...optSettings.timeLimit,
								value: Number(event.target.value),
							},
						})
					}
					type="number"
					label={t('seconds')}
				/>
			</DialogContent>

			<DialogActions style={{ marginTop: '1rem' }}>
				<Button color="primary" variant="contained" onClick={props.closeWindow}>
					Cancel
				</Button>
				<Button
					disabled={optSettings.lunch.enabled && !validStartEnd}
					color="primary"
					variant="contained"
					onClick={() => {
						props.updateSettings(optSettings);
						props.closeWindow();
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const DescriptionText = (props: {
	header: string;
	body: string;
	disabled: boolean;
}) => {
	return (
		<div className={props.disabled ? 'disabled' : undefined}>
			<Typography variant="subtitle2">{props.header}</Typography>
			<i>
				<Typography
					variant="caption"
					style={{ color: props.disabled ? 'inherit' : '#999' }}
				>
					{props.body}
				</Typography>
			</i>
		</div>
	);
};

const TimePicker = (props: TimePickerProps) => {
	return <MuiTimePicker {...props} ampm={false} />;
	// style={{ width: '8rem' }} />
};
