import { Fragment, useState, useEffect } from 'react';
import { Operation, OperationType, TEAMCOLORS } from '../../models/Models';
import Draggable, { DraggableData } from 'react-draggable';
import {
	Button,
	Collapse,
	Fade,
	Paper,
	Tooltip,
	Typography,
	useTheme,
	IconButton,
} from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import {
	assignTeams,
	scheduleOptimizer,
	Tracks,
	STARTOFDAY,
} from '../../api/ApiUtils';
import chroma from 'chroma-js';
import { Snackbar, TeamMultiSelect, Typo } from './../Common/Common';
import { OptimizeScheduleSettingsDialog } from './OptimizeScheduleSettingsDialog';
import { SnackbarState } from '../../models/CommonTypes';
import { useTranslation } from 'react-i18next';
import {
	OptimizationSettings,
	OptimizeScheduleProps,
	SelectionState,
	TeamOptimizeSchedule,
} from './types';
import { isColliding, isOverlapping } from './ScheduleUtils';
import Spinner from '../Common/Spinner';

const MINUTE_RATIO = 1;

const NON_SELECT_BG = 'rgba(127, 127, 127, 0.25)';
const SELECT_BG = 'rgba(221, 255, 221, 0.5)';

/**
 * Displays a Timeline between 08:00 -> 24:00
 */
function Timeline() {
	return (
		<div style={{ marginBottom: '1rem' }}>
			{genHours().map((t, index) => (
				<div
					key={index}
					style={{
						display: 'inline-block',
						width: 60 * MINUTE_RATIO,
						left: '50%',
						WebkitTransform: 'translate(-50%, 0)',
					}}
				>
					<Typo align="center" key={index} variant="subtitle2">
						{t}
					</Typo>
				</div>
			))}
		</div>
	);
}

/**
 * Helper function for TimeLine
 */
function genHours(): Array<string> {
	const arr = [];
	for (let i = 8; i <= 24; i++) arr.push(`${String(i).padStart(2, '0')}:00`);
	return arr;
}

/**
 * Converts a number to HH:MM format string
 * @param x number, timeline
 */
function xToHM(x: number): string {
	const hour = String(Math.floor(x / 60) + 8).padStart(2, '0');
	const minute = String(Math.floor(x % 60)).padStart(2, '0');
	return `${hour}:${minute}`;
}

export default function OptimizeSchedule(props: OptimizeScheduleProps) {
	const { t } = useTranslation();
	const theme = useTheme();

	const colors = props.teams.reduce<{ [key: string]: chroma.Color }>(
		(acc, current, index) => {
			acc[current.name] = TEAMCOLORS[index];
			return acc;
		},
		{}
	);

	const [isOptimizing, setIsOptimizing] = useState(false);

	const [currentSelection, setCurrentSelection] = useState<SelectionState>({
		opIndex: 0,
		bgColor: NON_SELECT_BG,
		roomIndex: -1,
		hold: false,
		currentStart: 0,
		forbiddenMove: false,
		showOptimized: false,
		canOptimize:
			props.operations.flatMap((item) => item.operations).length > 0 &&
			props.operations.length <=
				props.teams.filter((team) => team.active).length, // checks for number of operations
		canAssign: false,
		teams: props.teams,
		moveChange: false,
	});

	useEffect(() => {
		setCurrentSelection({
			...currentSelection,
			canOptimize:
				props.operations.flatMap((item) => item.operations).length > 0 &&
				props.operations.length <=
					props.teams.filter((team) => team.active).length,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.operations]);

	const canOptimize = (teams: Array<TeamOptimizeSchedule>) => {
		return (
			props.operations.length <= teams.filter((team) => team.active).length &&
			props.operations.length > 0
		);
	};

	const setColor = () => {
		if (currentSelection.forbiddenMove) {
			return 'red';
		} else if (theme.palette.mode === 'light') {
			return 'black';
		} else return 'white';
	};

	/** Controls the snackbar */
	const [snackbar, setSnackbar] = useState<SnackbarState>({
		messageType: 'ok',
		message: '',
		open: false,
	});

	/** Tracks used for Drag and Drop operations */
	const [tracks, setTracks] = useState<Array<Tracks>>(
		props.operations.map((x) => {
			return {
				tracks: x.operations.map(() => 0),
				maxTracks: 1,
			};
		})
	);

	/** Scheduled operations, starts same as ordinary operations */
	const [scheduledOps, setScheduledOps] = useState<Array<OperationType>>([
		...props.operations,
	]);

	/** Keeps track of tracks :), if there is overlaps between ops in the same room. */
	const [scheduleTracks, setScheduleTracks] = useState<Array<Tracks>>(
		props.operations.map((x) => {
			return {
				tracks: x.operations.map(() => -1),
				maxTracks: 1,
			};
		})
	);

	/** Settings window */
	const [openSetingsWindow, setOpenSettingsWindow] = useState<boolean>(false);
	const [optSettings, setOptSettings] = useState<OptimizationSettings>({
		tPrep: { enabled: false, value: 15 },
		modelType: { enabled: false, value: 'discrete' },
		timeLimit: { enabled: false, value: 15 },
		lunch: { enabled: false, value: { start: new Date(), end: new Date() } },
	});

	/** Will update starting locations for all operations when lunchbreak is set or unset */
	useEffect(() => {
		const lStart =
			optSettings.lunch.value.start.getHours() * 60 +
			optSettings.lunch.value.start.getMinutes() -
			STARTOFDAY;
		const lEnd =
			optSettings.lunch.value.end.getHours() * 60 +
			optSettings.lunch.value.end.getMinutes() -
			STARTOFDAY;

		const opsCopy = [...props.operations];
		for (let room of opsCopy) {
			let accum: number = 0;
			for (let op of room.operations) {
				if (
					optSettings.lunch.enabled &&
					((accum >= lStart && accum <= lEnd) ||
						(accum + op.duration > lStart && accum + op.duration < lEnd) ||
						(accum < lStart && accum + op.duration > lEnd))
				) {
					op.start = lEnd;
				} else op.start = accum;

				accum =
					op.start +
					op.preOpTime +
					op.opTime +
					op.postOpTime +
					optSettings.tPrep.value;
			}
		}

		props.setOperations(opsCopy);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optSettings]);

	/**
	 * Called when moving an operation
	 * Sets state variable: forbiddenMove and currenStart,
	 * these variable helps with drawing timestamp and display valid moves.
	 * @param e Object event object
	 * @param data DraggableData used by third party library
	 * @param opIndex number index of the operation inside the operating room
	 * @param roomIndex number index of the operating room
	 */
	const dragHandle = (
		e: Object,
		data: DraggableData,
		opIndex: number,
		roomIndex: number
	) => {
		/** Check for collision */
		const opA = { ...props.operations[roomIndex].operations[opIndex] };
		opA.start = data.x;

		let collision = false;

		for (const item of props.operations[roomIndex].operations.filter(
			(op) => op.locked && op.operationId !== opA.operationId
		)) {
			collision = isColliding(opA, item);
			if (collision) break;
		}

		setCurrentSelection({
			...currentSelection,
			currentStart: data.x,
			forbiddenMove: collision,
		});
	};

	/**
	 * Called when dropping an operation.
	 * Will check for valid moves: overlap and collisions with other operations
	 * @param e Object
	 * @param data DraggableData, used to third-party library
	 * @param opIndex number index of the draggable Operation
	 * @param roomIndex number index of the room, of the draggable Operation
	 */
	const dropHandle = (
		e: Object,
		data: DraggableData,
		opIndex: number,
		roomIndex: number
	) => {
		const newArr = [...props.operations];

		const opA = { ...props.operations[roomIndex].operations[opIndex] };
		opA.start = data.x;

		let collision = false;
		const overlapArr: Array<boolean> = [];

		/* Check for collision and overlaps with other operations in the same operating room */
		for (const item of newArr[roomIndex].operations.filter(
			(op) => op.operationId !== opA.operationId
		)) {
			collision = isColliding(opA, item) && item.locked;
			overlapArr.push(isOverlapping(opA, item));
			if (collision) break;
		}

		const isOutsideBorder = opA.start < 0 || opA.start + opA.duration > 16 * 60;

		if (!collision && !isOutsideBorder) {
			newArr[roomIndex].operations[opIndex].start = data.x;
			newArr[roomIndex].operations[opIndex].locked = true;
			props.setOperations(newArr);

			const newTracks = [...tracks];
			/** Getting a list of operations that overlaps with opA */
			const cand = overlapArr.reduce<Array<number>>((acc, item, ind) => {
				if (
					ind !== opIndex &&
					item &&
					!acc.includes(tracks[roomIndex].tracks[ind])
				)
					acc.push(tracks[roomIndex].tracks[ind]); // pushing trackNr
				return acc;
			}, []);

			let spaceFound = false;
			let trackNr = 0;
			while (!spaceFound) {
				if (cand.includes(trackNr)) trackNr = trackNr + 1;
				else spaceFound = true;
			}
			newTracks[roomIndex].tracks[opIndex] = trackNr;
			newTracks[roomIndex].maxTracks =
				Math.max(...tracks[roomIndex].tracks) + 1;

			setTracks(newTracks);
		}

		setCurrentSelection({
			...currentSelection,
			bgColor: NON_SELECT_BG,
			hold: false,
			moveChange: true,
		});
	};

	/**
	 * Draws a 3 rectangles: preOp, op and postOp blocks based on respective durations.
	 * @param topLevel boolean. Used to set z-index (CSS)
	 * @param locked boolean, if the should be rendered as a locked operation
	 * @param roomIndex number, index of the room in props.data
	 * @param opIndex number, index of the operation in the operation array
	 */
	function SurgeryCard(
		op: Operation,
		topLevel: boolean,
		locked: boolean,
		roomIndex: number,
		opIndex: number
	) {
		const preOpColor: string = op.preOpTeam
			? colors[op.preOpTeam].brighten().hex()
			: '#eee';
		const opColor: string = op.opTeam ? colors[op.opTeam].hex() : '#aaa';
		const postOpColor: string = op.postOpTeam
			? colors[op.postOpTeam].brighten().hex()
			: '#eee';
		return (
			<Paper
				key={op.name}
				elevation={1}
				style={{
					height: 18,
					backgroundColor: 'inherit',
					position: 'absolute',
					display: 'flex',
					zIndex: topLevel ? 100 : 10,
					border: `${locked ? 1.5 : 0.75}px solid black`,
				}}
			>
				<div
					className={locked ? 'locked-pattern' : ''}
					style={{
						width: op.preOpTime * MINUTE_RATIO,
						backgroundColor: preOpColor,
					}}
					onClick={
						locked ? (e) => unlockOperation(roomIndex, opIndex) : undefined
					}
				/>
				<Typography
					className="op"
					variant="caption"
					component="div"
					style={{
						width: op.opTime * MINUTE_RATIO,
						textAlign: 'center',
						backgroundColor: opColor,
					}}
				>
					{op.operationId}
				</Typography>
				<div
					className={locked ? 'locked-pattern' : ''}
					style={{ width: op.postOpTime, backgroundColor: postOpColor }}
					onClick={
						locked ? (e) => unlockOperation(roomIndex, opIndex) : undefined
					}
				/>
			</Paper>
		);
	}

	const unlockOperation = (roomIndex: number, opIndex: number) => {
		const newArr = [...props.operations];
		newArr[roomIndex].operations[opIndex].locked = false;
		props.setOperations(newArr);
		setCurrentSelection({ ...currentSelection, moveChange: true });
	};

	return (
		<Fragment>
			<OptimizeScheduleSettingsDialog
				open={openSetingsWindow}
				closeWindow={() => setOpenSettingsWindow(false)}
				optSettings={optSettings}
				updateSettings={(newSettings: OptimizationSettings) =>
					setOptSettings({ ...newSettings })
				}
			/>

			<Collapse in={currentSelection.showOptimized} timeout={500}>
				<Fade
					in={currentSelection.showOptimized}
					timeout={3000}
					style={{
						transitionDelay: currentSelection.showOptimized ? '500ms' : '0ms',
					}}
				>
					<div style={{ margin: '0 auto', width: 'fit-content' }}>
						<Typo variant="h4">{t('Optimized Surgery Schedule')}</Typo>
						{scheduledOps.map((room, roomIndex) => (
							<Fragment key={`o-${roomIndex}`}>
								<Typo
									variant="subtitle2"
									style={{ margin: 0, padding: 0 }}
									display="inline"
								>
									{room.name}
								</Typo>
								<div
									key={`s-${room.name}`}
									style={{
										width: 16 * 60,
										border: '1px solid black',
										height: scheduleTracks[roomIndex].maxTracks * 35,
									}}
									className="scheduled"
								>
									{room.operations.map((op, opIndex) => (
										<Draggable
											key={`s-${op.name}`}
											position={{
												x: op.start * MINUTE_RATIO,
												y: scheduleTracks[roomIndex].tracks[opIndex] * 40,
											}}
										>
											{SurgeryCard(op, false, false, roomIndex, opIndex)}
										</Draggable>
									))}
								</div>
								<Timeline />
							</Fragment>
						))}
						<div style={{ textAlign: 'center' }}>
							<Button
								style={{ display: 'inline-block' }}
								variant="contained"
								color="primary"
								disabled={!currentSelection.canAssign}
								onClick={() => {
									assignTeams(
										scheduledOps,
										currentSelection.teams.reduce<Array<string>>(
											(acc, current) => {
												if (current.active) acc.push(current.name);
												return acc;
											},
											[]
										)
									)
										.then((res) => {
											setScheduledOps(res);
										})
										.catch((reason) => {
											setSnackbar({
												message: `${t(reason.message)}`,
												messageType: 'error',
												open: true,
											});
										});
									setCurrentSelection({
										...currentSelection,
										canAssign: false,
									});
								}}
							>
								{t('Assign Teams')}
							</Button>
						</div>
					</div>
				</Fade>
			</Collapse>
			{isOptimizing && <Spinner />}
			<div style={{ margin: '0 auto', width: 'fit-content' }}>
				<Typo variant="h4">{t('Surgery Schedule')}</Typo>
				{props.operations.map((room, roomIndex) => (
					<Fragment key={roomIndex}>
						<Typo
							key={`t-${roomIndex}`}
							variant="subtitle2"
							style={{ margin: 0, padding: 0 }}
							display="inline"
						>
							{room.name}
						</Typo>
						<Typography
							display="inline"
							style={{ left: '50%', position: 'absolute', color: setColor() }}
						>
							{currentSelection.hold &&
								currentSelection.roomIndex === roomIndex &&
								xToHM(currentSelection.currentStart)}
						</Typography>
						<div
							key={room.name}
							className="non-scheduled"
							style={{
								width: 16 * 60,
								border: '1px solid black',
								height: tracks[roomIndex].maxTracks * 35,
								backgroundColor:
									roomIndex === currentSelection.roomIndex
										? currentSelection.bgColor
										: NON_SELECT_BG,
							}}
						>
							{room.operations.map((op, opIndex) => (
								<Draggable
									handle=".op"
									allowAnyClick={false}
									key={op.name}
									position={{
										x: op.start * MINUTE_RATIO,
										y: tracks[roomIndex].tracks[opIndex] * 40,
									}}
									onDrag={(event, data) =>
										dragHandle(event, data, opIndex, roomIndex)
									}
									onStart={() =>
										setCurrentSelection({
											...currentSelection,
											opIndex: opIndex,
											bgColor: SELECT_BG,
											roomIndex: roomIndex,
											hold: true,
										})
									}
									onStop={(event, data) =>
										dropHandle(event, data, opIndex, roomIndex)
									}
								>
									{SurgeryCard(
										op,
										opIndex === currentSelection.opIndex,
										op.locked,
										roomIndex,
										opIndex
									)}
								</Draggable>
							))}
						</div>
						<Timeline />
					</Fragment>
				))}
			</div>

			<div style={{ textAlign: 'center' }}>
				<Tooltip
					title={String(t('optimization-settings-description'))}
					placement="top"
					arrow
				>
					<IconButton onClick={() => setOpenSettingsWindow(true)}>
						<SettingsIcon />
					</IconButton>
				</Tooltip>

				<div style={{ width: 400, display: 'inline-block' }}>
					<TeamMultiSelect
						options={props.teams.map((name, index) => {
							return {
								label: name.name,
								value: index,
								active: name.active,
								color: TEAMCOLORS[index].hex(),
							};
						})}
						setTeams={(teams: Array<TeamOptimizeSchedule>) =>
							setCurrentSelection({
								...currentSelection,
								teams: teams,
								canOptimize: canOptimize(teams),
							})
						}
					/>
				</div>

				<Tooltip title={String(t('ToolTipOptimizeSchedule'))} arrow>
					<span
						style={{
							verticalAlign: 'top',
							display: 'inline-block',
							marginLeft: '1rem',
						}}
					>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								setIsOptimizing(true);
								scheduleOptimizer(
									props.operations,
									currentSelection.teams.filter((item) => item.active).length,
									optSettings.tPrep.value,
									optSettings.lunch
								)
									.then((res) => {
										setScheduledOps(res.scheduledOps);
										setScheduleTracks(res.tracks);
										setSnackbar({
											message: `${t(res.message)}`,
											messageType: 'ok',
											open: true,
										});
										setCurrentSelection({
											...currentSelection,
											showOptimized: true,
											canOptimize: false,
											canAssign: true,
											moveChange: false,
										});
									})
									.catch((error) => {
										setSnackbar({
											message: error.message,
											open: true,
											messageType: 'error',
										});
									})
									.finally(() => {
										setIsOptimizing(false);
									});
							}}
							disabled={
								(!currentSelection.canOptimize &&
									!currentSelection.moveChange) ||
								isOptimizing
							}
						>
							{t('Optimize')}
						</Button>
					</span>
				</Tooltip>
			</div>

			<Snackbar
				messageType={snackbar.messageType}
				message={snackbar.message}
				open={snackbar.open}
				duration={6000}
				handleClose={() => setSnackbar({ ...snackbar, open: false })}
			/>
		</Fragment>
	);
}
