import { useState } from 'react';
import { Draggable, DropResult } from 'react-smooth-dnd';
import '../stepper.scss';
import { Typography, Paper, IconButton, Tooltip, Button } from '@mui/material';
import { IndeterminateCheckBox, AddBox, Settings } from '@mui/icons-material';
import StaffCard from './StaffCard';
import { StaffDialog } from './StaffDialog';
import {
	Staff,
	Profession,
	TEAMCOLORS as colors,
	TeamConstraint,
} from '../../models/Models';
import { LabelChanger } from '../Common/LabelChanger';
import { TeamSettingsDialog } from './TeamSettingsDialog';
import { Snackbar, DnDContainer } from '../Common/Common';
import { useTranslation } from 'react-i18next';
import { binPackStaff } from '../../api/ApiUtils';
import { CreateTeamsProps, CreateTeamsState } from './types';

const CreateTeams = (props: CreateTeamsProps) => {
	const { t } = useTranslation();

	/** Show the highest room nr that is non-empty */
	const [numActiveTeams, setNumActiveTeams] = useState<number>(() => {
		const active = props.staff.reduce<number>((acc, item, index) => {
			if (item.data.length > 0 && index > acc) acc = index;
			return acc;
		}, 0);
		return active === 0 ? 4 : active;
	});

	const [state, setState] = useState<CreateTeamsState>({
		openDialog: false,
		staffLocation: {
			columnIndex: 0,
			staffIndex: 0,
		},
		selectedStaff: props.staff[0].data[0],
		showForm: props.staff.map(() => false),
		snackBar: {
			open: false,
			message: '',
			messageType: 'ok',
		},
		lockDisabled: true,
		unavailableDisabled: false,
		teamSettingsDialog: false,
	});

	/** Sets numActiveTeams and sets active: true/false on team */
	const increaseActiveTeams = () => {
		setNumActiveTeams(numActiveTeams + 1);
		props.staff[numActiveTeams + 1].active = true;
	};

	const decreaseActiveTeams = () => {
		setNumActiveTeams(numActiveTeams - 1);
		props.staff[numActiveTeams].active = false;
	};

	const overLimit = (
		staff: Array<Staff>,
		constraints: Array<TeamConstraint>
	) => {
		var isOverLimit = false;

		/* check if limits are exceeded */
		const nrOfStaff = staff.length;
		const nrOfDoctors = staff.filter(
			(st) => st.profession === Profession.DOCTOR
		).length;
		const nrOfNurses = staff.filter(
			(st) => st.profession === Profession.NURSE
		).length;
		const nrOfSeniors = staff.filter((st) => st.senior === true).length;

		constraints.forEach((constr) => {
			if (constr.min || constr.min === 0) {
				if (
					(constr.name === 'Staff' && constr.min > nrOfStaff) ||
					(constr.name === 'Doctors' && constr.min > nrOfDoctors) ||
					(constr.name === 'Nurses' && constr.min > nrOfNurses) ||
					(constr.name === 'Seniors' && constr.min < nrOfSeniors)
				)
					isOverLimit = true;
			}
			if (constr.max || constr.max === 0) {
				if (
					(constr.name === 'Staff' && constr.max < nrOfStaff) ||
					(constr.name === 'Doctors' && constr.max < nrOfDoctors) ||
					(constr.name === 'Nurses' && constr.max < nrOfNurses) ||
					(constr.name === 'Seniors' && constr.max < nrOfSeniors)
				)
					isOverLimit = true;
			}
		});
		return isOverLimit;
	};

	/**
	 * Updates the data model state when user drags and drops a Staff.
	 * Important note: this function will be called twice, once for item removal and item adding.
	 * @param arr Staff[]
	 * @param dragResult: DropResult used by library
	 * @param staffIndex: number index for the specific data-row
	 */
	const applyDrag = (
		arr: Staff[],
		dragResult: DropResult,
		staffIndex: number
	) => {
		const { removedIndex, addedIndex, payload } = dragResult;
		if (removedIndex === null && addedIndex === null) return arr;

		const result = [...arr];
		let itemToAdd = payload;

		if (removedIndex !== null) itemToAdd = result.splice(removedIndex, 1)[0];

		if (addedIndex !== null) {
			result.splice(addedIndex, 0, itemToAdd);
			if (staffIndex === 0)
				// locks the patient
				payload.locked = false;
			else payload.locked = true;
		}

		/** Updating data state using callback */
		const copy = [...props.staff];
		copy[staffIndex].data = result;
		props.setStaff(copy);
	};

	/**
	 * Used for drawing disabled team containers
	 */
	let rowsToShow = numActiveTeams <= 4 ? 1 : 2;
	const remainder = numActiveTeams % 4;

	/**
	 * Opens the window, sets seleectedStaff with columnIndex and staffIndex
	 * @param staff Staff Object to be edited
	 * @param columnIndex number index for container
	 * @param staffIndex number, index for where Staff is located
	 */
	const openWindow = (
		staff: Staff,
		columnIndex: number = 0,
		staffIndex: number = 0
	) => {
		setState({
			...state,
			openDialog: true,
			selectedStaff: props.staff[columnIndex].data[staffIndex],
			staffLocation: {
				columnIndex: columnIndex,
				staffIndex: staffIndex,
			},
			lockDisabled:
				columnIndex === 0 ||
				!props.staff[columnIndex].data[staffIndex].available,
			unavailableDisabled: props.staff[columnIndex].data[staffIndex].locked,
		});
	};

	/**
	 * Closes the window and updated data
	 * Using deepy copy of entire data objects.
	 * @param staff Staff object
	 */
	const saveAndClose = (staff: Staff) => {
		const { columnIndex, staffIndex } = state.staffLocation;
		const copy = [...props.staff]; // deep copy
		copy[columnIndex].data[staffIndex] = staff;
		props.setStaff(copy);
		setState({
			...state,
			openDialog: false,
			snackBar: {
				...state.snackBar,
				open: true,
				messageType: 'ok',
				message: `${staff.name} ${t('updated')}.`,
			},
		}); // closing window
	};

	/**
	 * Changes the name of the container
	 * @param newValue string new value from textField
	 * @param index number, index from where the container name is located
	 */
	const changeName = (newValue: string, index: number = 0) => {
		const copy = [...props.staff];
		copy[index].name = newValue;
		props.setStaff(copy);
		setState({ ...state, showForm: state.showForm.map(() => false) });
	};

	return (
		<>
			<div className="page-grid">
				<div key="Staff" style={{ width: 220 }}>
					<LabelChanger
						namesData={{
							names: props.staff.map((item) => item.name),
							currentIndex: 0,
						}}
						show={state.showForm[0]}
						cancel={() =>
							setState({ ...state, showForm: state.showForm.map(() => false) })
						}
						setName={(name) => changeName(name, 0)}
						toggleChange={() => {
							setState({
								...state,
								showForm: state.showForm.map((x, formIndex) => 0 === formIndex),
							});
						}}
					/>
					<Paper
						elevation={0}
						className="card-group"
						style={{
							backgroundColor: '#BFBFBF',
							height: 'calc(2 * 30vh + 30px + 16px)',
						}}
					>
						<DnDContainer
							getChildPayload={(i) => props.staff[0].data[i]}
							onDrop={(e) => applyDrag(props.staff[0].data, e, 0)}
						>
							{props.staff[0].data.map((person, cardIndex) => {
								return (
									<Draggable key={`${cardIndex}-Staff-${person.name}`}>
										<StaffCard
											color={'#BFBFBF'}
											staff={person}
											teamIndex={0}
											staffIndex={cardIndex}
											staffColumns={props.staff}
											setStaffColumns={props.setStaff}
											setOpenWindow={(staff) => openWindow(staff, 0, cardIndex)}
										/>
									</Draggable>
								);
							})}
						</DnDContainer>
					</Paper>
				</div>

				<div
					className="team-grid"
					style={{ gridTemplateRows: `repeat( ${rowsToShow}, 1fr )` }}
				>
					{props.staff.slice(1, numActiveTeams + 1).map((item, index) => (
						<div key={item.name}>
							<LabelChanger
								namesData={{
									names: props.staff.map((item) => item.name),
									currentIndex: index + 1,
								}}
								show={state.showForm[index + 1]}
								cancel={() =>
									setState({
										...state,
										showForm: state.showForm.map(() => false),
									})
								}
								setName={(name) => changeName(name, index + 1)}
								toggleChange={() => {
									setState({
										...state,
										showForm: state.showForm.map(
											(x, formIndex) => index + 1 === formIndex
										),
									});
								}}
							/>
							<Paper
								elevation={0}
								className="card-group"
								style={{
									backgroundColor: colors[index].hex(),
									border: overLimit(item.data, item.constraints)
										? '2px solid red'
										: '1px solid #ccc',
									height:
										numActiveTeams <= 4
											? 'calc(2 * 30vh + 30px + 16px)'
											: '30vh',
								}}
							>
								<DnDContainer
									getChildPayload={(i) => item.data[i]}
									onDrop={(e) => applyDrag(item.data, e, index + 1)}
								>
									{item.data.map((person, cardIndex) => {
										return (
											<Draggable key={`${cardIndex}-${index}-${person.name}`}>
												<StaffCard
													color={colors[index].hex()}
													staff={person}
													teamIndex={index + 1}
													staffIndex={cardIndex}
													staffColumns={props.staff}
													setStaffColumns={props.setStaff}
													setOpenWindow={(staff) =>
														openWindow(staff, index + 1, cardIndex)
													}
												/>
											</Draggable>
										);
									})}
								</DnDContainer>
							</Paper>
						</div>
					))}
					{
						// Empty greyed out containers
						remainder > 0 &&
							props.staff
								.slice(numActiveTeams + 1, 4 - remainder + numActiveTeams + 1)
								.map((item) => (
									<div key={item.name}>
										<Typography variant="h5" style={{ color: '#ccc' }}>
											{item.name}
										</Typography>
										<Paper
											elevation={0}
											style={{
												backgroundColor: 'rgba(220, 220, 220, 0.3)',
												height:
													numActiveTeams <= 4
														? 'calc(2 * 30vh + 30px + 16px)'
														: '30vh',
												border: '2px dashed #ccc',
											}}
										></Paper>
									</div>
								))
					}
				</div>

				<div style={{ margin: 'auto' }}>
					<Tooltip title={String(t('Add team'))} placement="right" arrow>
						<span>
							<IconButton
								onClick={() => increaseActiveTeams()}
								disabled={numActiveTeams === 8}
							>
								<AddBox fontSize="large" />
							</IconButton>
						</span>
					</Tooltip>

					<Tooltip title={String(t('Remove team'))} placement="right" arrow>
						<span>
							<IconButton
								onClick={() => decreaseActiveTeams()}
								disabled={numActiveTeams === 1}
							>
								<IndeterminateCheckBox fontSize="large" />
							</IconButton>
						</span>
					</Tooltip>
				</div>
			</div>

			<div
				style={{
					textAlign: 'center',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Tooltip title={String(t('TeamConstraints'))}>
					<IconButton
						onClick={() => setState({ ...state, teamSettingsDialog: true })}
					>
						<Settings />
					</IconButton>
				</Tooltip>

				<Tooltip title={String(t('ToolTipDistribute'))} arrow>
					<Button
						onClick={() => {
							binPackStaff(props.staff)
								.then((res) => {
									props.setStaff(res);
									setState({
										...state,
										snackBar: {
											open: true,
											message: `${t('SnackBarDistributedStaff')}`,
											messageType: 'ok',
										},
									});
								})
								.catch((error: Error) => {
									setState({
										...state,
										snackBar: {
											open: true,
											message: `${error.message}`,
											messageType:
												error.name === 'Model infeasible' ? 'warning' : 'error',
										},
									});
								});
						}}
						variant="contained"
						color="primary"
						disabled={
							props.staff
								.flatMap((item) => item.data)
								.reduce<number>((acc: number, item: Staff) => {
									if (!item.locked && item.available) return acc + 1;
									else return acc;
								}, 0) === 0 // counts movable staff
						}
					>
						{t('Distribute')}
					</Button>
				</Tooltip>
			</div>

			<StaffDialog
				{...{
					staff: state.selectedStaff,
					open: state.openDialog,
					closeWindow: () => setState({ ...state, openDialog: false }),
					updateStaff: saveAndClose,
					lockDisabled: state.lockDisabled,
					unavailableDisabled: state.unavailableDisabled,
					setLockDisabled: () =>
						setState({
							...state,
							lockDisabled:
								state.staffLocation.columnIndex === 0
									? true
									: !state.lockDisabled,
						}),
					setUnavailableDisabled: () =>
						setState({
							...state,
							unavailableDisabled: !state.unavailableDisabled,
						}),
				}}
			/>

			<TeamSettingsDialog
				staff={props.staff}
				setStaff={props.setStaff}
				open={state.teamSettingsDialog}
				setOpen={(open: boolean) =>
					setState({ ...state, teamSettingsDialog: open })
				}
				addRefreshCallback={props.addRefreshCallback}
			/>

			<Snackbar
				messageType={state.snackBar.messageType}
				message={state.snackBar.message}
				open={state.snackBar.open}
				duration={6000}
				handleClose={() =>
					setState({ ...state, snackBar: { ...state.snackBar, open: false } })
				}
			/>
		</>
	);
};

export default CreateTeams;
