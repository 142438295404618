import { createContext } from 'react';
import { TeamConstraint } from '../models/Models';

export type PresetConstraint = {
	presetName: string;
	teamConstraint: Array<TeamConstraint>;
};

export const PresetConstraintsContext = createContext<{
	constraints: Array<PresetConstraint>;
}>({
	constraints: [],
	// setConstraints: () => {}
});

export const ConstraintsProvider = PresetConstraintsContext.Provider;
