import chroma from 'chroma-js';

/*
 * STAFF TYPES
 *
 * TeamConstraints: Constraints for a team
 * Staff: One staff person
 * StaffType: Team of Staff
 *
 */
export type TeamConstraint = {
	name: string;
	// name: 'Nurses' | 'Doctors' | 'Seniors' | 'Staff',  // Change to this.
	max: number | undefined;
	min: number | undefined;
};

export type Staff = {
	staffID: number;
	name: string;
	profession: Profession;
	specialisation: Specialisation;
	senior: boolean;
	locked: boolean;
	available: boolean;
};

export type StaffType = {
	bagID: number;
	name: string;
	data: Staff[];
	active: boolean;
	constraints: Array<TeamConstraint>;
};

/*
 * OPERATION TYPES
 *
 * OperationObj: To use in constructor for Operation
 * Operation: One operation
 * OperationType: Room containing operations
 *
 */

type OperationObj = {
	name: string;
	operationId: string;
	preOpTime: number;
	opTime: number;
	postOpTime: number;
	start: number;
	room?: string;
};

export class Operation {
	name: string;
	operationId: string;
	preOpTime: number;
	opTime: number;
	postOpTime: number;
	room: string;
	start: number;
	duration: number;
	preOpTeam?: string;
	opTeam?: string;
	postOpTeam?: string;
	locked: boolean;

	constructor(args: OperationObj) {
		const { name, operationId, preOpTime, opTime, postOpTime, room, start } =
			args;
		this.name = name;
		this.operationId = operationId;
		this.preOpTime = preOpTime;
		this.opTime = opTime;
		this.postOpTime = postOpTime;
		this.room = room || '';
		this.start = start;
		this.duration = this.preOpTime + this.opTime + this.postOpTime;
		this.preOpTeam = undefined;
		this.opTeam = undefined;
		this.postOpTeam = undefined;
		this.locked = false;
	}
}

export type OperationType = {
	name: string;
	operations: Operation[];
	duration: number;
};

/*
 * COLORS
 */
export const TEAMCOLORS = [
	chroma('#b9dbad'),
	chroma('#69827a'),
	chroma('#cb8a36'),
	chroma('#6d778d'),
	chroma('#77675a'),
	chroma('#9F3F3F'),
	chroma('#86659A'),
	chroma('#ACDAD7'),
];

/*
 * PROFESSION
 */
export enum Profession {
	NURSE,
	SURGEON,
	DOCTOR,
	ANESTHESIOLOGIST,
	TECHNOLOGIST,
}

export const PROFESSION_STR = [
	'Nurse',
	'Surgeon',
	'Doctor',
	'Anesthesiologist',
	'Technologist',
];

export const PROFESSION_CONVERTER: { [key: string]: Profession } = {
	NURSE: Profession.NURSE,
	SURGEON: Profession.SURGEON,
	DOCTOR: Profession.DOCTOR,
	ANESTHESIOLOGIST: Profession.ANESTHESIOLOGIST,
	TECHNOLOGIST: Profession.TECHNOLOGIST,
};

/*
 * SPECIALISATION
 */
export enum Specialisation {
	GENERAL,
	THORACIC,
	COLON_AND_RECTAL,
	OBSTETRICS_AND_GYNECOLOGY,
	GYNECOLOGIC_ONCOLOGY,
	NEUROLOGICAL,
	OPHTHALMIC,
	ORAL_AND_MAXILLOFACIAL,
	ORTHOPAEDIC,
	OTOLARYNGOLOGY,
	PEDIATRIC,
	PLASTIC_AND_MAXILLOFACIAL,
	UROLOGY,
	VASCULAR,
}

export const SPECIALISATION_STR = [
	'General',
	'Thoracic',
	'Colon and Rectal',
	'Obstetrics and Gynecology',
	'Gynecologic Oncology',
	'Neurological',
	'Ophthalmic',
	'Oral and Maxillofacial',
	'Orthopaedic',
	'Otolaryngology',
	'Pediatric',
	'Plastic and Maxillofacial',
	'Urology',
	'Vascular',
];

export const SPECIALISATION_CONVERTER: { [key: string]: Specialisation } = {
	GENERAL: Specialisation.GENERAL,
	THORACIC: Specialisation.THORACIC,
	'COLON AND RECTAL': Specialisation.COLON_AND_RECTAL,
	'OBSTETRICS AND GYNECOLOGY': Specialisation.OBSTETRICS_AND_GYNECOLOGY,
	'GYNECOLOGIC ONCOLOGY': Specialisation.GYNECOLOGIC_ONCOLOGY,
	NEUROLOGICAL: Specialisation.NEUROLOGICAL,
	OPHTALMIC: Specialisation.OPHTHALMIC,
	'ORAL AND MAXILLOFACIAL': Specialisation.ORAL_AND_MAXILLOFACIAL,
	ORTHOPAEDIC: Specialisation.ORTHOPAEDIC,
	OTOLARYNGOLOGY: Specialisation.OTOLARYNGOLOGY,
	PEDIATRIC: Specialisation.PEDIATRIC,
	'PLASTIC AND MAXILLOFACIAL': Specialisation.PLASTIC_AND_MAXILLOFACIAL,
	UROLOGY: Specialisation.UROLOGY,
	VASCULAR: Specialisation.VASCULAR,
};
